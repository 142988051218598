function SortData({ data, orderBy, direction }) {
  const compare = (a, b) => {
    let comparison = 0;
    if (a[orderBy] > b[orderBy]) {
      comparison = 1;
    } else if (a[orderBy] < b[orderBy]) {
      comparison = -1;
    }

    if (direction && direction.toLowerCase() === 'desc') {
      comparison *= -1;
    }

    return comparison;
  };

  return data.sort(compare);
}

const DataHelper = {
  SortData,
};

export default DataHelper;
