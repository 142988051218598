import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QuestionHelper from '../../helpers/question.helper';

const GatheringQuestions = ({ setQuestion }) => {
  const [questions, setQuestions] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function getQuestions() {
      try {
        setQuestions(await QuestionHelper.List());
      } catch (e) {
        console.log('getQuestions: ', e);
      }
    }
    getQuestions();
  }, []);

  return (
    <>
      <div className="choose-question-heading text-center pt-3">
        <h2>{t(('question_select'))}</h2>
      </div>
      <Container>
        {
            questions && (
              questions.map((question) => (
                <Row key={question.id} onClick={() => setQuestion(question)} className="my-3">
                  <Col>
                    <Card className="gathering-question-card drop-shadow">
                      <Card.Body className="px-2 py-2">
                        <Container className="px-2">
                          <Row>
                            <Col className="question">
                              {question.question}
                            </Col>
                          </Row>
                          <Row className="pt-2">
                            <Col className="question-stat">
                              {`${t('gatherings')}: -`}
                            </Col>
                            <Col className="question-stat">
                              {`${t('lanterns')}: -`}
                            </Col>
                            <Col />
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ))
            )
          }
      </Container>
    </>
  );
};

GatheringQuestions.propTypes = {
  setQuestion: PropTypes.func.isRequired,
};

export default GatheringQuestions;
