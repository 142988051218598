import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Alert,
  Spinner,
} from 'react-bootstrap';
import { useAppContext } from '../libs/contextLib';
import AuthHelper from '../helpers/auth.helper';
import UserHelper from '../helpers/user.helper';

const ConfirmSignUpForm = (props) => {
  const { t } = useTranslation();
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();
  const [generalError, setGeneralError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = Yup.object({
    auth_code: Yup
      .string()
      .min(6, (t('auth_code_too_short')))
      .max(6, (t('auth_code_too_long')))
      .required(t('auth_code_requried')),
  });

  const onSubmit = async (values) => {
    try {
      setGeneralError(null);
      setIsSubmitting(true);
      // eslint-disable-next-line camelcase
      const { auth_code } = values;
      const { username, password } = props.formState;
      const confirmedSignUp = await AuthHelper.confirmSignUp(username, auth_code);

      if (confirmedSignUp) {
        const user = await AuthHelper.signIn(username, password);

        if (user) {
          const identity = await AuthHelper.currentUserInfo();

          if (await UserHelper.Create(user.username, identity.id)) {
            userHasAuthenticated(true);

            // Send the user to their dashboard
            history.push('/dashboard');
          } else {
            setGeneralError(t('lantern_user_create_failed'));
          }
        } else {
          setGeneralError(t('login_credentials_invalid'));
        }
      } else {
        setGeneralError(t('auth_code_invalid'));
      }
    } catch (e) {
      console.log('onSubmit catch: ', e);
      setGeneralError(e.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <h2>{t('account_confirm')}</h2>
      <Formik
        initialValues={{ auth_code: '' }}
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} data-testid="confirmsignup">
            {
              generalError && (
                <Alert variant="danger">
                  {generalError}
                </Alert>
              )
            }
            <Form.Group>
              <Form.Control
                type="text"
                name="auth_code"
                placeholder={t('auth_code_requried')}
                value={values.auth_code}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.auth_code}
                data-testid="auth_code"
              />
              <Form.Control.Feedback type="invalid">
                {errors.auth_code}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {
                  isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null
                }
                {t('account_confirm')}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ConfirmSignUpForm.propTypes = {
  formState: PropTypes.string.isRequired,
};

export default ConfirmSignUpForm;
