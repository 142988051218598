import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { Storage } from 'aws-amplify';
import UserHelper from '../../helpers/user.helper';
import LanternTranscriptionHelper from '../../helpers/lanternTranscription.helper';
import LanternVideoPlayer from './videoPlayer';
import LanternUserSmall from './userSmall';

const LanternCard = ({ lantern }) => {
  const [lanternURL, setLanternURL] = useState('');
  const [transcriptionData, setTranscriptionData] = useState(null);

  useEffect(() => {
    async function getLanternURL() {
      const lanternUser = await UserHelper.GetById({
        id: lantern.gatheringUser.userId,
      });

      const currentUser = await UserHelper.Current();

      if (lantern.gatheringUser.userId === currentUser.id) {
        setLanternURL(await Storage.get(lantern.id, {
          level: 'protected',
        }));
      } else {
        const { identityId } = lanternUser;
        setLanternURL(await Storage.get(lantern.id, {
          level: 'protected',
          identityId,
        }));
      }
    }
    getLanternURL();
  }, [lantern]);

  useEffect(() => {
    async function getTranscriptionData() {
      if (!lantern) {
        return;
      }

      setTranscriptionData(await LanternTranscriptionHelper.GetTranscriptionForLantern({
        lanternId: lantern.id,
      }));
    }
    getTranscriptionData();
  }, [lantern]);

  return (
    <Col xs={12} className="px-0">
      <a href={`/lantern/view/${lantern.id}`}>
        <Card className="lantern-card px-2 py-2 drop-shadow">
          <Container>
            <Row>
              <Col xs={6}>
                {
                  lanternURL && transcriptionData && (
                    <LanternVideoPlayer src={lanternURL} transcriptionData={transcriptionData} type="video/mp4" />
                  )
                }
              </Col>
              <Col xs={6} className="lantern-card-details my-0 py-0 px-0">
                <Card.Body className="px-0 py-2">
                  <LanternUserSmall lantern={lantern} />
                </Card.Body>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    </Col>
  );
};

LanternCard.propTypes = {
  lantern: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gathering: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
    gatheringUser: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LanternCard;
