import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FlagIconFactory from 'react-flag-icon-css';

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const FlagIcon = FlagIconFactory(React, { useCssModules: false });

  const changeLanguage = (language) => {
    try {
      i18n.changeLanguage(language);
    } catch (e) {
      console.log('change language: ', e.message);
    }
  };

  return (
    <div>
      <DropdownButton variant="primary-outline" size="sm" title={t('language_select')} onSelect={changeLanguage}>
        <Dropdown.Item eventKey="us">
          <FlagIcon code="us" size="lg" />
          &nbsp;&nbsp;English
        </Dropdown.Item>
        <Dropdown.Item eventKey="ru">
          <FlagIcon code="ru" size="lg" />
          &nbsp;&nbsp;Russian
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default ChangeLanguage;
