import React from 'react';
import PropTypes from 'prop-types';
import heroVideo from '../videos/hero_video.mov';

const Hero = (props) => {
  const { children } = props;

  return (
    <header>
      <video playsInline autoPlay muted loop>
        <source
          src={heroVideo}
          type="video/mp4"
        />
      </video>
      {children}
    </header>
  );
};

Hero.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Hero;
