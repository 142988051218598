import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import {
  Container, Row, Col, Form, Button, InputGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import AuthHelper from '../../helpers/auth.helper';

// eslint-disable-next-line no-unused-vars
const AddGatheringParticipant = ({
  gatheringParticipants,
  setShowAddGatheringParticipant,
}) => {
  const [knownUsers, setKnownUsers] = useState(null);
  const { t } = useTranslation();

  const schema = Yup.object({
    phone_number: Yup.string().max(10, t('phone_invalid')).phone('US', t('phone_invalid')).required(t('required')),
  });

  useEffect(() => {
    async function getKnownUsers() {
      setKnownUsers(await AuthHelper.listUsers());
    }
    getKnownUsers();
  }, []);

  const findExistingCognitoUser = ({ phoneNumber }) => knownUsers.find((user) => AuthHelper.getUserAttribute(user, 'phone_number') === phoneNumber);

  const onSubmit = (values) => {
    const newParticipant = {
      phone_number: values.phone_number,
    };

    const user = findExistingCognitoUser({
      phoneNumber: `+1${newParticipant.phone_number}`,
    });

    if (user) {
      newParticipant.cognitoId = user.Username;
      newParticipant.given_name = user.Attributes.find((o) => o.Name === 'given_name').Value;
      newParticipant.family_name = user.Attributes.find((o) => o.Name === 'family_name').Value;
    }

    gatheringParticipants.push(newParticipant);
    setShowAddGatheringParticipant(false);
  };

  return (
    <Formik
      initialValues={{
        phone_number: '',
      }}
      validationSchema={schema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row className="flex-nowrap">
            <Container>
              <Row>
                <Col>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faMobileAlt} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      name="phone_number"
                      placeholder={t('phone_number')}
                      aria-describedby="inputGroupPrepend"
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.phone_number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone_number}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-3">
                  <Button variant="secondary" className="lantern-wide-button" type="submit">
                    {t('Add')}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

AddGatheringParticipant.propTypes = {
  gatheringParticipants: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
  setShowAddGatheringParticipant: PropTypes.func.isRequired,
};

export default AddGatheringParticipant;
