import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GatheringDashboard from '../gathering/dashboard';
import ExploreDashboard from '../lantern/dashboard';
import AuthHelper from '../../helpers/auth.helper';

const Dashboard = () => {
  const [givenName, setUserGivenName] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function userGivenName() {
      try {
        const currentUser = await AuthHelper.currentUserInfo();
        setUserGivenName(currentUser.attributes.given_name);
      } catch (e) {
        console.log('userGivenName: ', e);
      }
    }
    userGivenName();
  }, []);

  return (
    <>
      {
        givenName && (
          <Container fluid className="user-dashboard">
            <div className="background" />
            <Row>
              <Col xs={12} sm={3} className="left-column">
                <div className="dashboard-heading">
                  {t('dashboard_heading')}
                  {givenName}
                </div>
                <h3 className="dashboard-subheading">
                  {t('dashboard_subheading')}
                </h3>
                <GatheringDashboard />
              </Col>
              <Col xs={12} sm={9} className="px-0 right-column">
                <div className="d-sm-none lantern-mobile mt-4" />
                <ExploreDashboard />
              </Col>
            </Row>
          </Container>
        )
      }
    </>
  );
};

export default Dashboard;
