import { API, graphqlOperation } from 'aws-amplify';
import { listQuestions } from '../graphql/queries';

async function List() {
  try {
    const responseData = await API.graphql(graphqlOperation(listQuestions));
    return responseData.data.listQuestions.items;
  } catch (e) {
    console.log('getQuestions: ', e);
  }

  return null;
}

const QuestionHelper = {
  List,
};

export default QuestionHelper;
