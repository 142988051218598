import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import LanternTranscriptionHelper from '../../../helpers/lanternTranscription.helper';

const ReTranslateTranscription = ({
  lantern, fromTranscriptionData, toTranscriptionData, language,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleRetranslateTranscription = async () => {
    await LanternTranscriptionHelper.ReTranslateTranscription({
      lantern,
      fromTranscriptionId: fromTranscriptionData.id,
      toTranscriptionId: toTranscriptionData.id,
      language,
    });
    history.go(0);
  };

  return (
    <Button variant="secondary" className="lantern-wide-button lantern-command-button" onClick={handleRetranslateTranscription}>
      {t('transcription_retranslate')}
    </Button>
  );
};

ReTranslateTranscription.propTypes = {
  lantern: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  fromTranscriptionData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  toTranscriptionData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  language: PropTypes.string.isRequired,
};

export default ReTranslateTranscription;
