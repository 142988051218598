import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ChevronIcon } from '../images/icons/arrow.svg';

const PageHeading = ({ headingText, headingURL, button }) => (
  <div className="page-heading drop-shadow px-3 pt-5 pb-3 d-sm-none">
    <a className="header-link" href={headingURL}>
      <Container>
        <Row>
          <Col xs={1} className="px-0">
            <ChevronIcon className="flip-image" />
          </Col>
          <Col xs={11} className="px-0">
            <Container className="px-0">
              <Row>
                <Col>
                  {headingText}
                </Col>
                {
                  button === 'Create' && (
                    <Col className="text-right">
                      <Button href="/gathering/create" className="primary create-lantern-button">
                        <span className="pr-2">
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                        {button}
                      </Button>
                    </Col>
                  )
                }
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </a>
  </div>
);

PageHeading.propTypes = {
  headingText: PropTypes.string.isRequired,
  headingURL: PropTypes.string.isRequired,
  button: PropTypes.string,
};

PageHeading.defaultProps = {
  button: null,
};

export default PageHeading;
