import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Alert,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMobileAlt,
  faEnvelope,
  faBirthdayCake,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import AuthHelper from '../helpers/auth.helper';

const SignUpForm = (props) => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = Yup.object({
    phone_number: Yup.string().max(10, t('phone_invalid')).phone('US', false, t('phone_invalid')).required(t('required')),
    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, t('password_invalid')).required(t('required')),
    email: Yup.string().email(t('email_invalid')).required(t('required')),
    given_name: Yup.string().min(2, (t('name_too_short'))).max(16, (t('name_too_long'))).required(t('required')),
    family_name: Yup.string().min(2, (t('name_too_short'))).max(16, (t('name_too_long'))).required(t('required')),
    dob: Yup.date().required(t('required')),
  });

  const onSubmit = async (values) => {
    try {
      setGeneralError(null);
      setIsSubmitting(true);
      const {
        // eslint-disable-next-line camelcase
        phone_number,
        password,
        email,
        // eslint-disable-next-line camelcase
        given_name,
        // eslint-disable-next-line camelcase
        family_name,
        dob,
      } = values;
      // eslint-disable-next-line camelcase
      const username = `+1${phone_number}`;
      const { user, error } = await AuthHelper.signUp(
        username,
        password,
        email,
        given_name,
        family_name,
        dob,
      );

      if (error) {
        setGeneralError(error.message);
      } else if (user) {
        props.updateFormState(() => ({ formType: 'confirmSignUp', username, password }));
      } else {
        setGeneralError(t('unknown_error'));
      }
    } catch (e) {
      console.log('onSubmit catch: ', e);
      setGeneralError(e.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <h3>{t('sign_up')}</h3>
      <Formik
        initialValues={{
          phone_number: '',
          password: '',
          email: '',
          given_name: '',
          family_name: '',
          dob: '',
        }}
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {
              generalError && (
                <Alert variant="danger">
                  {generalError}
                </Alert>
              )
            }
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMobileAlt} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder={t('phone_number')}
                  aria-describedby="inputGroupPrepend"
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.phone_number}
                  data-testid="phone_number"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone_number}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faLock} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder={t('password')}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.password}
                  data-testid="password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder={t('email')}
                  aria-describedby="inputGroupPrepend"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.email}
                  data-testid="email"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="given_name"
                placeholder={t('first_name')}
                value={values.given_name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.given_name}
                data-testid="given_name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.given_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                name="family_name"
                placeholder={t('last_name')}
                value={values.family_name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={errors.family_name}
                data-testid="family_name"
              />
              <Form.Control.Feedback type="invalid">
                {errors.family_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faBirthdayCake} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="dob"
                  placeholder={t('date_of_birth')}
                  aria-describedby="inputGroupPrepend"
                  value={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.dob}
                  data-testid="dob"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center">
              <Button variant="primary" className="rounded-pill" type="submit" disabled={isSubmitting}>
                {
                  isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null
                }
                {t('sign_up')}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

SignUpForm.propTypes = {
  updateFormState: PropTypes.func.isRequired,
};

export default SignUpForm;
