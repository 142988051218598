import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import AuthHelper from '../../helpers/auth.helper';
import SegmentHelper from '../../helpers/segment.helper';
import ChangeLanguage from '../changeLanguage';

const ViewProfile = ({ userHasSignedOut }) => {
  const { t } = useTranslation();

  const handleSignOut = () => {
    AuthHelper.signOut();
    userHasSignedOut(true);
    SegmentHelper.Track({
      actor: 'user',
      device: 'desktop',
      component: 'account',
      action: 'signout',
    });
  };

  return (
    <Container className="mt-5 py-5">
      <Row>
        <Col xs={12} className="text-center">
          <Button variant="secondary lantern-wide-button lantern-command-button" onClick={handleSignOut}>
            {t('sign_out')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mt-5 text-center">
          <ChangeLanguage />
        </Col>
      </Row>
    </Container>
  );
};

ViewProfile.propTypes = {
  userHasSignedOut: PropTypes.func.isRequired,
};

export default ViewProfile;
