/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        gatherings {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGathering = /* GraphQL */ `
  query GetGathering($id: ID!) {
    getGathering(id: $id) {
      id
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      questionId
      question {
        id
        question
        gatherings {
          nextToken
        }
        createdAt
        updatedAt
      }
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGatherings = /* GraphQL */ `
  query ListGatherings(
    $filter: ModelGatheringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGatherings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGatheringUser = /* GraphQL */ `
  query GetGatheringUser($id: ID!) {
    getGatheringUser(id: $id) {
      id
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      invitePhoneNumber
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGatheringUsers = /* GraphQL */ `
  query ListGatheringUsers(
    $filter: ModelGatheringUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGatheringUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserRelationship = /* GraphQL */ `
  query GetUserRelationship($id: ID!) {
    getUserRelationship(id: $id) {
      id
      fromUserId
      fromUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      toUserId
      toUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      relationshipType
      relationshipQualifier
      createdAt
      updatedAt
    }
  }
`;
export const listUserRelationships = /* GraphQL */ `
  query ListUserRelationships(
    $filter: ModelUserRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRelationships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromUserId
        fromUser {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        toUserId
        toUser {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        relationshipType
        relationshipQualifier
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLantern = /* GraphQL */ `
  query GetLantern($id: ID!) {
    getLantern(id: $id) {
      id
      gatheringUserId
      gatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      pathToLantern
      createdAt
      updatedAt
    }
  }
`;
export const listLanterns = /* GraphQL */ `
  query ListLanterns(
    $filter: ModelLanternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanterns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLanternTranscription = /* GraphQL */ `
  query GetLanternTranscription($id: ID!) {
    getLanternTranscription(id: $id) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      transcript
      language
      webvtt
      createdAt
      updatedAt
    }
  }
`;
export const listLanternTranscriptions = /* GraphQL */ `
  query ListLanternTranscriptions(
    $filter: ModelLanternTranscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanternTranscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lanternId
        lantern {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        transcript
        language
        webvtt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLanternProxy = /* GraphQL */ `
  query GetLanternProxy($id: ID!) {
    getLanternProxy(id: $id) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      byGatheringUserId
      byGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      forGatheringUserId
      forGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLanternProxys = /* GraphQL */ `
  query ListLanternProxys(
    $filter: ModelLanternProxyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanternProxys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lanternId
        lantern {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        byGatheringUserId
        byGatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        forGatheringUserId
        forGatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gatheringByQuestion = /* GraphQL */ `
  query GatheringByQuestion(
    $questionId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGatheringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gatheringByQuestion(
      questionId: $questionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gatheringByUser = /* GraphQL */ `
  query GatheringByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGatheringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gatheringByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByCognitoId = /* GraphQL */ `
  query UserByCognitoId(
    $cognitoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCognitoId(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byGatheringId = /* GraphQL */ `
  query ByGatheringId(
    $gatheringId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGatheringUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byGatheringId(
      gatheringId: $gatheringId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gatheringByGatheringUser = /* GraphQL */ `
  query GatheringByGatheringUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGatheringUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gatheringByGatheringUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gatheringUserByUserIdAndGatheringId = /* GraphQL */ `
  query GatheringUserByUserIdAndGatheringId(
    $userId: ID
    $gatheringId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGatheringUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gatheringUserByUserIdAndGatheringId(
      userId: $userId
      gatheringId: $gatheringId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const lanternByGatheringId = /* GraphQL */ `
  query LanternByGatheringId(
    $gatheringId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLanternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lanternByGatheringId(
      gatheringId: $gatheringId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const lanternByGatheringUserId = /* GraphQL */ `
  query LanternByGatheringUserId(
    $gatheringUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLanternFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lanternByGatheringUserId(
      gatheringUserId: $gatheringUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transcriptionByLanternId = /* GraphQL */ `
  query TranscriptionByLanternId(
    $lanternId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLanternTranscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transcriptionByLanternId(
      lanternId: $lanternId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lanternId
        lantern {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        transcript
        language
        webvtt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const proxyByLanternId = /* GraphQL */ `
  query ProxyByLanternId(
    $lanternId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLanternProxyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    proxyByLanternId(
      lanternId: $lanternId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lanternId
        lantern {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        byGatheringUserId
        byGatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        forGatheringUserId
        forGatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const proxyByGatheringUserId = /* GraphQL */ `
  query ProxyByGatheringUserId(
    $byGatheringUserId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLanternProxyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    proxyByGatheringUserId(
      byGatheringUserId: $byGatheringUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lanternId
        lantern {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        byGatheringUserId
        byGatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        forGatheringUserId
        forGatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const settingByName = /* GraphQL */ `
  query SettingByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    settingByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
