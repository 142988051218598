import { API, graphqlOperation } from 'aws-amplify';
import { createGatheringUser } from '../graphql/mutations';
import {
  listGatheringUsers,
  gatheringByGatheringUser,
  byGatheringId,
  gatheringUserByUserIdAndGatheringId,
  getGatheringUser,
} from '../graphql/queries';
import UserHelper from './user.helper';
import AuthHelper from './auth.helper';
import DataHelper from './data.helper';
// eslint-disable-next-line import/no-cycle
import LanternHelper from './lantern.helper';

async function Create({ gatheringId, userId, invitePhoneNumber }) {
  try {
    const gatheringUser = {
      gatheringId,
      userId,
      invitePhoneNumber,
    };
    return await API.graphql(graphqlOperation(createGatheringUser, { input: gatheringUser }));
  } catch (e) {
    console.log('createGatheringUser: ', e);
  }

  return null;
}

async function List({ gatheringId }) {
  try {
    const filterOptions = {
      gatheringId: { eq: gatheringId },
    };

    const responseData = await API.graphql(graphqlOperation(
      listGatheringUsers,
      { filter: filterOptions },
    ));
    return DataHelper.SortData({
      data: responseData.data.listGatheringUsers.items,
      orderBy: 'updatedAt',
      direction: 'desc',
    });
  } catch (e) {
    console.log('getGatheringUsers: ', e);
  }

  return null;
}

async function ListGatheringsForCurrentUser() {
  try {
    const user = await UserHelper.Current();

    if (user) {
      const responseData = await API.graphql(graphqlOperation(
        gatheringByGatheringUser,
        { userId: user.id },
      ));
      return DataHelper.SortData({
        data: responseData.data.gatheringByGatheringUser.items.map((item) => item.gathering),
        orderBy: 'updatedAt',
        direction: 'desc',
      });
    }
  } catch (e) {
    console.log('ListGatheringsForCurrentUser: ', e);
  }

  return null;
}

async function ListGatheringUsersForGathering({ gatheringId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(
      byGatheringId,
      { gatheringId },
    ));
    return DataHelper.SortData({
      data: responseData.data.byGatheringId.items,
      orderBy: 'updatedAt',
      direction: 'desc',
    });
  } catch (e) {
    console.log('ListGatheringUsersForGathering: ', e);
  }

  return null;
}

async function GetById({ gatheringUserId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(
      getGatheringUser,
      { id: gatheringUserId },
    ));
    return responseData.data.getGatheringUser;
  } catch (e) {
    console.log('GetById: ', e);
  }

  return null;
}

async function GetGatheringUserData({ gatheringUser }) {
  try {
    const responseData = await AuthHelper.getUser({
      username: gatheringUser.user.cognitoId,
    });

    if (responseData) {
      const userData = {
        id: gatheringUser.id,
      };
      responseData.UserAttributes.forEach((attribute) => {
        userData[attribute.Name] = attribute.Value;
      });

      return userData;
    }
  } catch (e) {
    console.log('GetGatheringUserData: ', e);
  }

  return null;
}

async function GetGatheringUserDataForGathering({ gatheringId }) {
  try {
    const gatheringUsers = await ListGatheringUsersForGathering({
      gatheringId,
    });

    if (gatheringUsers) {
      return Promise.all(gatheringUsers.map(async (gatheringUser) => {
        if (gatheringUser.user) {
          return GetGatheringUserData({
            gatheringUser,
          });
        }

        return gatheringUser;
      }));
    }
  } catch (e) {
    console.log('GetGatheringUserDataForGathering: ', e);
  }

  return null;
}

async function GetCurrentGatheringUserForGathering({ gatheringId }) {
  try {
    const user = await UserHelper.Current();

    if (user) {
      const input = {
        userId: user.id,
        gatheringId: { eq: gatheringId },
      };

      const responseData = await API.graphql(graphqlOperation(
        gatheringUserByUserIdAndGatheringId,
        input,
      ));
      return responseData.data.gatheringUserByUserIdAndGatheringId.items[0];
    }
  } catch (e) {
    console.log('GetCurrentGatheringUserForGathering: ', e);
  }

  return null;
}

async function ListGatheringUsersForCurrentUser() {
  try {
    const user = await UserHelper.Current();

    if (user) {
      const responseData = await API.graphql(graphqlOperation(
        gatheringByGatheringUser,
        { userId: user.id },
      ));

      return DataHelper.SortData({
        data: responseData.data.gatheringByGatheringUser.items,
        orderBy: 'updatedAt',
        direction: 'desc',
      });
    }
  } catch (e) {
    console.log('ListGatheringUsersForCurrentUser: ', e);
  }

  return null;
}

async function UserHasLanternForGathering({ gatheringUser, gatheringId }) {
  try {
    const lanterns = await LanternHelper.List({ gatheringId });

    if (lanterns) {
      return lanterns.find((lantern) => lantern.gatheringUserId === gatheringUser.id);
    }
  } catch (e) {
    console.log('UserHasLanternForGathering: ', e);
  }

  return null;
}

async function ListGatheringsWithoutLanternFromCurrentUser() {
  try {
    const gatherings = await ListGatheringsForCurrentUser();

    if (gatherings) {
      const gatheringsList = [];
      await Promise.all(gatherings.map(async (gathering) => {
        const hasLantern = await UserHasLanternForGathering({
          gatheringUser: await GetCurrentGatheringUserForGathering({ gatheringId: gathering.id }),
          gatheringId: gathering.id,
        });

        if (!hasLantern) {
          gatheringsList.push(gathering);
        }
      }));

      return gatheringsList;
    }
  } catch (e) {
    console.log('ListGatheringsWithoutLanternFromCurrentUser: ', e);
  }

  return null;
}

const GatheringUserHelper = {
  Create,
  List,
  ListGatheringsForCurrentUser,
  ListGatheringUsersForGathering,
  GetById,
  GetGatheringUserDataForGathering,
  GetGatheringUserData,
  GetCurrentGatheringUserForGathering,
  ListGatheringUsersForCurrentUser,
  UserHasLanternForGathering,
  ListGatheringsWithoutLanternFromCurrentUser,
};

export default GatheringUserHelper;
