import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GatheringHelper from '../../helpers/gathering.helper';

const GatheringSmallCard = ({ gatheringId, url }) => {
  const [gathering, setGathering] = useState(null);
  const { t } = useTranslation();

  const targetUrl = url || `/gathering/view/${gatheringId}`;

  useEffect(() => {
    async function getGathering() {
      setGathering(await GatheringHelper.GetById({
        gatheringId,
      }));
    }
    getGathering();
  }, [gatheringId]);

  return (
    <Card className="mt-3 mb-3 gathering-card-sm drop-shadow">
      {
        gathering && (
          <a href={targetUrl}>
            <Card.Body className="px-2">
              {gathering.question.question}
              <hr />
              <span className="gathering-stats">
                {gathering.lanterns.items.length}
                {' '}
                {t('lanterns')}
              </span>
            </Card.Body>
          </a>
        )
      }
    </Card>
  );
};

GatheringSmallCard.propTypes = {
  gatheringId: PropTypes.string.isRequired,
  url: PropTypes.string,
};

GatheringSmallCard.defaultProps = {
  url: null,
};

export default GatheringSmallCard;
