import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AuthHelper from '../../helpers/auth.helper';
import GatheringHelper from '../../helpers/gathering.helper';
import UserHelper from '../../helpers/user.helper';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import PageHeading from '../pageHeading';
import GatheringQuestions from './questions';
import { ReactComponent as ProfileImage } from '../../images/icons/Account.svg';
import AddGatheringParticipant from './addGatheringParticipant';

const CreateGathering = () => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [question, setQuestion] = useState(null);
  const [gatheringParticipants, setGatheringParticipants] = useState([]);
  const [showAddGatheringParticipant, setShowAddGatheringParticipant] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function setInitialUser() {
      try {
        const currentUser = await AuthHelper.currentUserInfo();
        setGatheringParticipants([{
          cognitoId: currentUser.attributes.sub,
          phone_number: currentUser.attributes.phone_number.replace('+1', ''),
          given_name: currentUser.attributes.given_name,
          family_name: currentUser.attributes.family_name,
        }]);
      } catch (e) {
        console.log('getCurrentUser:', e);
      }
    }
    setInitialUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    setIsSubmitting(true);
    setGeneralError(null);

    try {
      const user = await UserHelper.Current();

      if (user) {
        const gathering = await GatheringHelper.Create({
          user,
          questionId: question.id,
        });

        if (gathering) {
          gatheringParticipants.forEach(async (gatheringParticipant) => {
            const input = {
              gatheringId: gathering.id,
              userId: 0,
              invitePhoneNumber: 0,
            };

            if (gatheringParticipant.cognitoId && gatheringParticipant.cognitoId !== '') {
              const thisUser = await UserHelper.GetByCognitoId({
                cognitoId: gatheringParticipant.cognitoId,
              });
              input.userId = thisUser.id;
            } else {
              input.invitePhoneNumber = `+1${gatheringParticipant.phone_number}`;
            }

            const gatheringUser = await GatheringUserHelper.Create(input);
            if (gatheringUser) {
              // Send user to view the gathering they'd just created
              history.push(`/gathering/view/${gathering.id}`);
            } else {
              setGeneralError(t('gathering_create_failed'));
            }
          });
        } else {
          setGeneralError(t('gathering_create_failed'));
        }
      } else {
        setGeneralError(t('user_data_failed_get'));
      }
    } catch (e) {
      console.log('onSubmit: ', e);
      setGeneralError(e.message);
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <PageHeading headingText={t('gather')} headingURL="/gathering/list" />
      {
        !question && (
          <GatheringQuestions setQuestion={setQuestion} />
        )
      }
      {
        question && (
          <Container>
            <Row>
              <Col sm={12} className="justify-content-center">
                <div className="create-gathering-heading text-center my-5">
                  <h2>{question.question}</h2>
                </div>
                <Card.Body className="create-gathering-details drop-shadow">
                  {
                    generalError && (
                      <Alert variant="danger">
                        {generalError}
                      </Alert>
                    )
                  }
                  <Container>
                    <Row>
                      <Col className="participants-heading mb-2 px-1">
                        {`${t('participants')} (${gatheringParticipants.length})`}
                      </Col>
                    </Row>
                    <Row>
                      {
                        gatheringParticipants.map((gatheringParticipant) => (
                          <Col key={gatheringParticipant.phone_number} xs={2} className="participant text-center px-0 mx-1 mb-2">
                            <ProfileImage title={`${gatheringParticipant.given_name} ${gatheringParticipant.family_name}`} />
                          </Col>
                        ))
                      }
                      <Col xs={2} className="participant text-center px-0 mx-1">
                        <Button variant="secondary" className="rounded-circle px-0 py-0" onClick={() => setShowAddGatheringParticipant(true)}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </Col>
                    </Row>
                    {
                      showAddGatheringParticipant && (
                        <Row>
                          <Col className="pt-4">
                            <AddGatheringParticipant
                              gatheringParticipants={gatheringParticipants}
                              setShowAddGatheringParticipant={setShowAddGatheringParticipant}
                            />
                          </Col>
                        </Row>
                      )
                    }
                    <Row className="pt-5 text-center">
                      <Col>
                        <Button variant="secondary" className="lantern-wide-button lantern-command-button" onClick={onSubmit} disabled={isSubmitting}>
                          {
                            isSubmitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2"
                              />
                            )
                          }
                          {t('save')}
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Col>
            </Row>
          </Container>
        )
      }
    </>
  );
};

export default CreateGathering;
