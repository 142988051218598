import SegmentHelper from './segment.helper';

async function Footer() {
  await SegmentHelper.Identify();
}

async function PageLoad() {
  await SegmentHelper.Page();
}

const AnalyticsHelper = {
  Footer,
  PageLoad,
};

export default AnalyticsHelper;
