import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import LanternTranscriptionHelper from '../../../helpers/lanternTranscription.helper';

const DeleteTranscription = ({ transcriptionData }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleDeleteTranscription = async () => {
    await LanternTranscriptionHelper.DeleteTranscription({
      id: transcriptionData.id,
    });
    history.go(0);
  };

  return (
    <Button variant="secondary" className="lantern-wide-button lantern-command-button" onClick={handleDeleteTranscription}>
      {t('transcription_delete')}
    </Button>
  );
};

DeleteTranscription.propTypes = {
  transcriptionData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteTranscription;
