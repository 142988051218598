import React, { useState, useEffect } from 'react';
import {
  Container, Row, Tabs, Tab,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanternHelper from '../../helpers/lantern.helper';
import LanternCardLarge from './cardLarge';
import PageHeading from '../pageHeading';

const ListLanterns = () => {
  const [lanterns, setLanterns] = useState(null);
  const [numLanterns, setNumLanterns] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    async function getLanterns() {
      setLanterns(await LanternHelper.ListLanternsAvailableToCurrentUser());
    }
    getLanterns();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNumLanterns(lanterns ? lanterns.length : 0);
  }, [lanterns]);

  return (
    <>
      <PageHeading headingText={t('explore')} headingURL="/dashboard" />
      <Tabs className="explore-lantern-tabs px-2 py-2" defaultActiveKey="viewAll">
        <Tab eventKey="viewAll" title={`${t('lanterns_all')} (${numLanterns})`}>
          <Container>
            {
              lanterns && (
                lanterns.map((lantern) => (
                  <Row className="mb-3">
                    <LanternCardLarge key={lantern.id} lantern={lantern} />
                  </Row>
                ))
              )
            }
          </Container>
        </Tab>
      </Tabs>
    </>
  );
};

export default ListLanterns;
