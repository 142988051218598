import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import ReactTimeAgo from 'react-time-ago';
import AuthHelper from '../../helpers/auth.helper';
import UserHelper from '../../helpers/user.helper';
import LanternHelper from '../../helpers/lantern.helper';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import ProfileImage from '../user/profileImage';

const LanternUser = ({ lantern }) => {
  const [userData, setUserData] = useState({ given_name: '', family_name: '' });

  useEffect(() => {
    async function getUserData() {
      const lanternProxy = await LanternHelper.GetProxyByLanternId({
        lanternId: lantern.id,
      });

      const user = await UserHelper.GetById({
        id: lanternProxy ? lanternProxy.forGatheringUser.userId : lantern.gatheringUser.userId,
      });

      if (user) {
        const cognitoUser = await AuthHelper.getUser({
          username: user.cognitoId,
        });

        if (cognitoUser) {
          setUserData({
            name: `${AuthHelper.getUserAttribute(cognitoUser, 'given_name')} ${AuthHelper.getUserAttribute(cognitoUser, 'family_name')}`,
          });
        }
      } else {
        const gatheringUserId = lanternProxy
          ? lanternProxy.forGatheringUser.id
          : lantern.gatheringUser.id;
        const gatheringUser = await GatheringUserHelper.GetById({
          gatheringUserId,
        });

        if (gatheringUser) {
          setUserData({
            name: gatheringUser.invitePhoneNumber,
          });
        }
      }
    }
    getUserData();
  }, [lantern]);

  return (
    <>
      {
        lantern && (
          <Container>
            <Row>
              <Col xs={12}>
                <div className="lantern-creator">
                  <ProfileImage gatheringUser={lantern.gatheringUser} />
                  {userData.name}
                  {' | '}
                  <ReactTimeAgo date={lantern.createdAt} locale="en-US" />
                </div>
              </Col>
            </Row>
          </Container>
        )
      }
    </>
  );
};

LanternUser.propTypes = {
  lantern: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gatheringUser: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default LanternUser;
