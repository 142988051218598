import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Tabs, Tab,
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Storage } from 'aws-amplify';
import LanternHelper from '../../helpers/lantern.helper';
import GatheringHelper from '../../helpers/gathering.helper';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import PageHeading from '../pageHeading';
import LanternVideoPlayer from './videoPlayer';
import UserHelper from '../../helpers/user.helper';
import LanternTranscriptionHelper from '../../helpers/lanternTranscription.helper';
import LanternUser from './user';
import LanternTranscription from './transcription/view';

const ViewLantern = () => {
  const [canAccess, setCanAccess] = useState(false);
  const [lantern, setLantern] = useState(null);
  const [gathering, setGathering] = useState(null);
  const [lanternURL, setLanternURL] = useState('');
  const [transcriptionData, setTranscriptionData] = useState(null);
  const { t } = useTranslation();
  const { lanternId } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function canAccessGathering() {
      const userGatherings = await GatheringUserHelper.ListGatheringsForCurrentUser({
        gatheringId: lantern.gatheringId,
      });

      if (userGatherings) {
        // eslint-disable-next-line max-len
        const thisCanAccess = userGatherings.find((userGathering) => userGathering.id === lantern.gatheringId);
        setCanAccess(thisCanAccess);
        if (!thisCanAccess) {
          history.push('/');
        }
      }
    }
    if (lantern) {
      canAccessGathering();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lantern]);

  useEffect(() => {
    async function getLantern() {
      setLantern(await LanternHelper.GetById({
        lanternId,
      }));
    }
    getLantern();
  }, [lanternId]);

  useEffect(() => {
    async function getGathering() {
      if (lantern) {
        setGathering(await GatheringHelper.GetById({
          gatheringId: lantern.gatheringId,
        }));
      }
    }
    getGathering();
  }, [lantern]);

  useEffect(() => {
    async function getLanternURL() {
      if (!lantern) {
        return;
      }

      const lanternUser = await UserHelper.GetById({
        id: lantern.gatheringUser.userId,
      });

      const currentUser = await UserHelper.Current();

      if (lantern.gatheringUser.userId === currentUser.id) {
        setLanternURL(await Storage.get(lantern.id, {
          level: 'protected',
        }));
      } else {
        const { identityId } = lanternUser;
        setLanternURL(await Storage.get(lantern.id, {
          level: 'protected',
          identityId,
        }));
      }
    }
    getLanternURL();
  }, [lantern]);

  useEffect(() => {
    async function getTranscriptionData() {
      if (!lantern) {
        return;
      }

      setTranscriptionData(await LanternTranscriptionHelper.GetTranscriptionForLantern({
        lanternId: lantern.id,
      }));
    }
    getTranscriptionData();
  }, [lantern]);

  return (
    <>
      {
        canAccess && lantern && gathering && (
          <>
            <PageHeading headingText={t('watch')} headingURL={`/gathering/view/${gathering.id}`} />
            <div className="lantern-view">
              <Container fluid>
                <Row>
                  <Col className="lantern-info py-3">
                    {gathering.question.question}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={5} className="px-0 sticky-video">
                    <div className="lantern-video drop-shadow">
                      {
                        lanternURL && transcriptionData && (
                          <LanternVideoPlayer src={lanternURL} transcriptionData={transcriptionData} type="video/mp4" />
                        )
                      }
                      <div className="px-0 py-3">
                        <LanternUser lantern={lantern} />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={7} className="lantern-details">
                    <div className="py-2 d-sm-none" />
                    <Tabs className="lantern-transcript-tabs" defaultActiveKey="transcript">
                      <Tab eventKey="transcript" title={t('transcript')}>
                        <LanternTranscription lantern={lantern} />
                      </Tab>
                    </Tabs>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )
      }
    </>
  );
};

export default ViewLantern;
