import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import AuthHelper from '../../helpers/auth.helper';
import UserHelper from '../../helpers/user.helper';
import LanternHelper from '../../helpers/lantern.helper';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import ProfileImage from '../user/profileImage';

const LanternUserSmall = ({ lantern }) => {
  const [lanternData, setLanternData] = useState(null);
  const [userData, setUserData] = useState({ given_name: '', family_name: '' });

  useEffect(() => {
    async function getLantern() {
      setLanternData(await LanternHelper.GetById({
        lanternId: lantern.id,
      }));
    }
    getLantern();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getUserData() {
      if (!lanternData) {
        return;
      }

      const lanternProxy = await LanternHelper.GetProxyByLanternId({
        lanternId: lanternData.id,
      });

      const user = await UserHelper.GetById({
        id: lanternProxy ? lanternProxy.forGatheringUser.userId : lanternData.gatheringUser.userId,
      });

      if (user) {
        const cognitoUser = await AuthHelper.getUser({
          username: user.cognitoId,
        });

        if (cognitoUser) {
          setUserData({
            name: `${AuthHelper.getUserAttribute(cognitoUser, 'given_name')} ${AuthHelper.getUserAttribute(cognitoUser, 'family_name')}`,
          });
        }
      } else {
        const gatheringUserId = lanternProxy
          ? lanternProxy.forGatheringUser.id
          : lanternData.gatheringUser.id;
        const gatheringUser = await GatheringUserHelper.GetById({
          gatheringUserId,
        });

        if (gatheringUser) {
          setUserData({
            name: gatheringUser.invitePhoneNumber,
          });
        }
      }
    }
    getUserData();
  }, [lanternData]);

  return (
    <>
      {
        lanternData && (
          <Container>
            <Row>
              <Col xs={12}>
                <div className="lantern-creator">
                  <ProfileImage gatheringUser={lanternData.gatheringUser} />
                  {userData.name}
                </div>
              </Col>
            </Row>
          </Container>
        )
      }
    </>
  );
};

LanternUserSmall.propTypes = {
  lantern: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gatheringUser: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LanternUserSmall;
