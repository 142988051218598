import { isMobile } from 'react-device-detect';
import AuthHelper from './auth.helper';

async function Identify() {
  // eslint-disable-next-line no-undef
  if (!window.analytics) {
    return;
  }

  try {
    const user = await AuthHelper.currentUserInfo();
    if (user) {
      // eslint-disable-next-line no-undef
      window.analytics.identify(user.id);
    }
  } catch (e) {
    console.log('SegmentHelper.Identify: ', e);
  }
}

function Page() {
  // eslint-disable-next-line no-undef
  if (window.analytics) {
    // eslint-disable-next-line no-undef
    window.analytics.page();
  }
}

function getEventName(event) {
  const {
    actor, device, component, action,
  } = event;
  return `${actor}.${device}.${component}.${action}`;
}

function Track(event) {
  // eslint-disable-next-line no-undef
  if (window.analytics) {
    if (!event.device) {
      // eslint-disable-next-line no-param-reassign
      event.device = isMobile ? 'mobile' : 'desktop';
    }

    // eslint-disable-next-line no-undef
    window.analytics.track(getEventName(event));
  }
}

const SegmentHelper = {
  Identify,
  Page,
  Track,
};

export default SegmentHelper;
