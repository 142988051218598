import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import GatheringSmallCard from './smallCard';
import { ReactComponent as ChevronIcon } from '../../images/icons/arrow.svg';

const GatheringDashboard = () => {
  const [gatherings, setGatherings] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function getGatherings() {
      setGatherings(await GatheringUserHelper.ListGatheringsForCurrentUser({}));
    }
    getGatherings();
  }, []);

  return (
    <Container>
      <Row>
        <Col className="px-0">
          <div>
            <a className="header-link" href="/gathering/list">
              <Container>
                <Row>
                  <Col xs={10} className="px-0">
                    {t('gatherings')}
                  </Col>
                  <Col xs={2} className="px-0 text-right">
                    <ChevronIcon />
                  </Col>
                </Row>
              </Container>
            </a>
          </div>
          <h3>
            {t('gathering_dashboard_subheading')}
          </h3>
        </Col>
      </Row>
      <Row>
        {
          gatherings && (
            gatherings.slice(0, 2).map((gathering) => (
              <>
                <Col xs={6} sm={12} key={gathering.id} className="px-3">
                  <GatheringSmallCard key={gathering.id} gatheringId={gathering.id} />
                </Col>
              </>
            ))
          )
        }
      </Row>
    </Container>
  );
};

export default GatheringDashboard;
