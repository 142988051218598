import React, { useState, useEffect } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GatheringUserHelper from '../helpers/gatheringUser.helper';
import { ReactComponent as HomeInactiveIcon } from '../images/icons/home_inactive.svg';
import { ReactComponent as HomeActiveIcon } from '../images/icons/home_active.svg';
import { ReactComponent as ExploreInactiveIcon } from '../images/icons/explore_inactive.svg';
import { ReactComponent as ExploreActiveIcon } from '../images/icons/explore_active.svg';
import { ReactComponent as CreateInactiveIcon } from '../images/icons/create_inactive.svg';
import { ReactComponent as CreateActiveIcon } from '../images/icons/create_active.svg';
import { ReactComponent as GatherInactiveIcon } from '../images/icons/gather_inactive.svg';
import { ReactComponent as GatherActiveIcon } from '../images/icons/gather_active.svg';
import { ReactComponent as ProfileInactiveIcon } from '../images/icons/profile_inactive.svg';
import { ReactComponent as ProfileActiveIcon } from '../images/icons/profile_active.svg';

const NavigationMobile = () => (
  <Navbar fixed="bottom" className="d-sm-none lantern-mobile">
    <div className="nav-background" />
    <Nav className="mx-auto my-auto pb-2">
      <Home />
      <Explore />
      <Create />
      <Gather />
      <Profile />
    </Nav>
  </Navbar>
);

function Home() {
  const match = useRouteMatch('/dashboard');
  const { t } = useTranslation();

  return (
    <Nav.Item className="mt-auto text-center">
      <NavLink
        to="/dashboard"
      >
        {
          match
            ? <HomeActiveIcon />
            : <HomeInactiveIcon />
        }
        <div>
          {t('home')}
        </div>
      </NavLink>
    </Nav.Item>
  );
}

function Explore() {
  const match = useRouteMatch('/lantern/list');
  const { t } = useTranslation();

  return (
    <Nav.Item className="mt-auto text-center">
      <NavLink
        to="/lantern/list"
      >
        {
          match
            ? <ExploreActiveIcon />
            : <ExploreInactiveIcon />
        }
        <div>
          {t('explore')}
        </div>
      </NavLink>
    </Nav.Item>
  );
}

function Create() {
  const match = useRouteMatch('/lantern/create');
  const { t } = useTranslation();
  const [gatheringsToDo, setGatheringsToDo] = useState(null);

  useEffect(() => {
    async function getGatheringsToDo() {
      const data = await GatheringUserHelper.ListGatheringsWithoutLanternFromCurrentUser();
      setGatheringsToDo(data ? data.length : 0);
    }
    getGatheringsToDo();
  }, []);

  return (
    <Nav.Item className="mt-auto text-center">
      <NavLink
        to="/lantern/create"
      >
        {
          match
            ? <CreateActiveIcon />
            : <CreateInactiveIcon />
        }
        <div>
          {t('create')}
          <span className={`navbar-create-count${match ? ' active' : ''}`}>{gatheringsToDo}</span>
        </div>
      </NavLink>
    </Nav.Item>
  );
}

function Gather() {
  const match = useRouteMatch('/gathering/list');
  const { t } = useTranslation();

  return (
    <Nav.Item className="mt-auto text-center">
      <NavLink
        to="/gathering/list"
      >
        {
          match
            ? <GatherActiveIcon />
            : <GatherInactiveIcon />
        }
        <div>
          {t('gather')}
        </div>
      </NavLink>
    </Nav.Item>
  );
}

function Profile() {
  const match = useRouteMatch('/profile');
  const { t } = useTranslation();

  return (
    <Nav.Item className="mt-auto text-center">
      <NavLink to="/profile">
        {
          match
            ? <ProfileActiveIcon />
            : <ProfileInactiveIcon />
        }
        <div>
          {t('profile')}
        </div>
      </NavLink>
    </Nav.Item>
  );
}

export default NavigationMobile;
