import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container, Row, Col, Button, Tabs, Tab,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import GatheringHelper from '../../helpers/gathering.helper';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import LanternHelper from '../../helpers/lantern.helper';
import UserHelper from '../../helpers/user.helper';
import LanternCard from '../lantern/card';
import GatheringUsers from './users';
import PageHeading from '../pageHeading';

const ViewGathering = () => {
  const [gathering, setGathering] = useState(null);
  const [canAccess, setCanAccess] = useState(false);
  const [numLanterns, setNumLanterns] = useState(0);
  const { gatheringId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    async function canAccessGathering() {
      const userGatherings = await GatheringUserHelper.ListGatheringsForCurrentUser({
        gatheringId,
      });

      if (userGatherings) {
        // eslint-disable-next-line max-len
        const thisCanAccess = userGatherings.find((userGathering) => userGathering.id === gatheringId);
        setCanAccess(thisCanAccess);
        if (!thisCanAccess) {
          history.push('/');
        }
      }
    }
    canAccessGathering();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatheringId]);

  useEffect(() => {
    async function getGathering() {
      setGathering(await GatheringHelper.GetById({
        gatheringId,
      }));
    }
    getGathering();
  }, [gatheringId]);

  return (
    <>
      <PageHeading headingText={t('gathering')} headingURL="/gathering/list" />
      {
        canAccess && gathering && (
          <>
            <GatheringInfo gathering={gathering} />
            <div className="d-sm-block mt-4" />
            <Tabs className="gathering-lantern-tabs px-2 pb-2 d-none d-sm-none" defaultActiveKey="viewAll">
              <Tab eventKey="viewAll" title={`${t('view_all')} (${numLanterns})`}>
                <Lanterns gathering={gathering} setNumLanterns={setNumLanterns} />
              </Tab>
            </Tabs>
          </>
        )
      }
    </>
  );
};

function GatheringInfo({ gathering }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [isGatheringOrganizer, setIsGatheringOrganizer] = useState(false);

  useEffect(() => {
    async function checkIsGatheringOrganizer() {
      if (!gathering || !gathering.user) {
        return;
      }

      const user = await UserHelper.Current();
      if (gathering.user.id === user.id) {
        setIsGatheringOrganizer(true);
      }
    }
    checkIsGatheringOrganizer();
  }, [gathering]);

  return (
    <div className="gathering-info pt-4">
      <h3>
        {gathering.question.question}
      </h3>
      <Container className="mt-4 pb-2">
        <Row>
          <Col xs={12} sm={7}>
            <GatheringUsers gathering={gathering} />
          </Col>
          <Col xs={12} sm={3}>
            <Button className="primary mt-2" onClick={() => history.push(`/gathering/upload/${gathering.id}`)}>
              <span className="pr-2">
                <FontAwesomeIcon icon={faPlus} />
              </span>
              {t('lantern_create')}
            </Button>
          </Col>
          <Col xs={12} sm={2}>
            {
              isGatheringOrganizer && (
                <div className="mt-2">
                  <SendInvites gathering={gathering} />
                </div>
              )
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

GatheringInfo.propTypes = {
  gathering: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    question: PropTypes.shape({
      question: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function Lanterns({ gathering, setNumLanterns }) {
  const [lanterns, setLanterns] = useState(null);

  useEffect(() => {
    async function getLanterns() {
      setLanterns(await LanternHelper.List({
        gatheringId: gathering.id,
      }));
    }
    getLanterns();
  }, [gathering.id]);

  useEffect(() => {
    setNumLanterns(lanterns ? lanterns.length : 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lanterns]);

  return (
    <Container>
      {
        lanterns && (
          lanterns.map((lantern) => (
            <Row key={lantern.id} className="mt-3">
              <LanternCard key={lantern.id} lantern={lantern} />
            </Row>
          ))
        )
      }
    </Container>
  );
}

Lanterns.propTypes = {
  gathering: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  setNumLanterns: PropTypes.func.isRequired,
};

function SendInvites({ gathering }) {
  const { t } = useTranslation();
  return (
    <Button onClick={async () => {
      GatheringHelper.SendInvites({
        gatheringId: gathering.id,
      });
    }}
    >
      {t('invites_send')}
    </Button>
  );
}

SendInvites.propTypes = {
  gathering: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ViewGathering;
