import React from 'react';
import { Nav, Navbar, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NavigationHome = () => (
  <Navbar expand="lg" className="ml-15 mr-15">
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Home />
    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
      <Nav>
        <SignIn />
        <SignUp />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

function Home() {
  return (
    <Navbar.Brand href="/">
      <span className="logo">
        lantern
        <span className="logo-dot">.</span>
      </span>
    </Navbar.Brand>
  );
}

function SignIn() {
  const { t } = useTranslation();
  return (
    <Nav.Item className="px-4">
      <Nav.Link href="/signin">
        {t('sign_in')}
      </Nav.Link>
    </Nav.Item>
  );
}

function SignUp() {
  const { t } = useTranslation();
  return (
    <Nav.Item className="pt-1">
      <Button href="/signup" className="rounded-pill">
        {t('sign_up')}
      </Button>
    </Nav.Item>
  );
}

export default NavigationHome;
