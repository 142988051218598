import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import SegmentHelper from '../../helpers/segment.helper';
import SettingHelper from '../../helpers/setting.helper';

const options = {
  fill: true,
  fluid: true,
  responsive: true,
  preload: 'auto',
  controls: true,
  textTrackSettings: false,
};

const LanternVideoPlayer = (props) => {
  const [zoomSetting, setZoomSetting] = useState(false);
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const hasPlayed = useRef(null);

  const { transcriptionData } = props;
  const { src } = props;
  const { type } = props;

  let defaultLanguage = 'en-US';
  transcriptionData.forEach((data) => {
    if (i18n.language === data.language.split('-')[0]) {
      defaultLanguage = data.language;
    }
  });

  useEffect(() => {
    async function getZoomSetting() {
      setZoomSetting(await SettingHelper.GetByName({
        name: 'video_zoom',
      }));
    }
    getZoomSetting();
  }, []);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, options);
    setPlayer(vjsPlayer);

    return () => vjsPlayer.dispose();
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.src({ src: `${src}#t=0.1`, type });
      player.on('play', () => {
        if (hasPlayed.current) {
          return;
        }

        hasPlayed.current = true;
        SegmentHelper.Track({
          actor: 'user',
          component: 'lantern',
          action: 'play',
        });
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, type, player]);

  const zoomedStyle = {};
  if (zoomSetting && zoomSetting.value > 0) {
    zoomedStyle.transform = `scale(${zoomSetting.value})`;
    zoomedStyle.top = '50%';
  }

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      ref={videoRef}
      className="video-js vjs-16-9 vjs-big-play-centered"
      crossOrigin="anonymous"
      playsInline
      style={zoomedStyle}
    >
      {
          transcriptionData && transcriptionData.map((data) => {
            if (defaultLanguage === data.language) {
              return (
                <track key={data.id} kind="subtitles" src={`${data.url}?=${encodeURIComponent(data.updatedAt)}`} srcLang={data.language} label={data.language} default />
              );
            }
            return (
              <track key={data.id} kind="subtitles" src={`${data.url}?=${encodeURIComponent(data.updatedAt)}`} srcLang={data.language} label={data.language} />
            );
          })
        }
    </video>
  );
};

LanternVideoPlayer.propTypes = {
  transcriptionData: PropTypes.arrayOf(PropTypes.shape({
    lantern: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    language: PropTypes.string.isRequired,
    map: PropTypes.func,
  }).isRequired),
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

LanternVideoPlayer.defaultProps = {
  transcriptionData: null,
};

export default LanternVideoPlayer;
