import { Auth, API } from 'aws-amplify';

async function currentSession() {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (e) {
    console.log('currentSession: ', e);
  }

  return false;
}

async function hasValidSession() {
  return await currentSession() !== false;
}

async function currentUserInfo() {
  return Auth.currentUserInfo();
}

// eslint-disable-next-line camelcase
async function signUp(username, password, email, given_name, family_name, birthdate) {
  let error;

  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        given_name,
        family_name,
        birthdate,
      },
    });

    return { user };
  } catch (e) {
    console.log('signUp: ', e);

    if (e.code === 'UsernameExistsException') {
      error = {
        message: 'User already exists.',
      };
    }
  }

  return { error };
}

async function confirmSignUp(username, authCode) {
  try {
    await Auth.confirmSignUp(username, authCode);
    return true;
  } catch (e) {
    console.log('confirmSignUp: ', e);
  }

  return false;
}

async function signIn(username, password) {
  return Auth.signIn(username, password);
}

async function signOut() {
  try {
    await Auth.signOut();
    return true;
  } catch (e) {
    console.log('signOut: ', e);
  }

  return false;
}

async function listUsers() {
  const apiName = 'AdminQueries';
  const path = '/listUsers';
  const myInit = {
    queryStringParameters: {},
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const { NextToken, ...rest } = await API.get(apiName, path, myInit);
  return rest.Users;
}

async function getUser({ username }) {
  const apiName = 'AdminQueries';
  const path = '/getUser';
  const myInit = {
    queryStringParameters: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const { NextToken, ...rest } = await API.get(apiName, path, myInit);
  return rest;
}

function getUserAttribute(user, desiredAttribute) {
  const attributes = user.UserAttributes ? user.UserAttributes : user.Attributes;
  const found = attributes.find((attr) => attr.Name === desiredAttribute);
  if (found) {
    return found.Value;
  }

  return null;
}

async function AssignUserToGroup({ username, groupname }) {
  try {
    const apiName = 'AdminQueries';
    const path = '/addUserToGroup';
    const myInit = {
      body: {
        username,
        groupname,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
  } catch (e) {
    console.log('AssignUserToGroup: ', e);
  }

  return null;
}

const AuthHelper = {
  currentSession,
  hasValidSession,
  currentUserInfo,
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  listUsers,
  getUser,
  getUserAttribute,
  AssignUserToGroup,
};

export default AuthHelper;
