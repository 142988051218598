import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
} from 'react-bootstrap';
import { Storage } from 'aws-amplify';
import UserHelper from '../../helpers/user.helper';
import GatheringHelper from '../../helpers/gathering.helper';
import LanternTranscriptionHelper from '../../helpers/lanternTranscription.helper';
import LanternVideoPlayer from './videoPlayer';
import LanternUser from './user';

const LanternCardLarge = ({ lantern }) => {
  const [lanternURL, setLanternURL] = useState('');
  const [gathering, setGathering] = useState(null);
  const [transcriptionData, setTranscriptionData] = useState(null);

  useEffect(() => {
    async function getLanternURL() {
      const lanternUser = await UserHelper.GetById({
        id: lantern.gatheringUser.userId,
      });

      const currentUser = await UserHelper.Current();

      if (lantern.gatheringUser.userId === currentUser.id) {
        setLanternURL(await Storage.get(lantern.id, {
          level: 'protected',
        }));
      } else {
        const { identityId } = lanternUser;
        setLanternURL(await Storage.get(lantern.id, {
          level: 'protected',
          identityId,
        }));
      }
    }
    getLanternURL();
  }, [lantern]);

  useEffect(() => {
    async function getGathering() {
      setGathering(await GatheringHelper.GetById({
        gatheringId: lantern.gatheringId,
      }));
    }
    getGathering();
  }, [lantern]);

  useEffect(() => {
    async function getTranscriptionData() {
      if (!lantern) {
        return;
      }

      setTranscriptionData(await LanternTranscriptionHelper.GetTranscriptionForLantern({
        lanternId: lantern.id,
      }));
    }
    getTranscriptionData();
  }, [lantern]);

  return (
    <>
      {
        gathering && (
          <Col xs={12} className="px-0 lantern-gathering-card">
            <a href={`/lantern/view/${lantern.id}`}>
              <div className="lantern-gathering-question text-center p-3">
                {gathering.question.question}
              </div>
              <div className="lantern-video drop-shadow">
                {
                  lanternURL && transcriptionData && (
                    <LanternVideoPlayer src={lanternURL} transcriptionData={transcriptionData} type="video/mp4" />
                  )
                }
                <div className="px-2 py-3">
                  <LanternUser lantern={lantern} />
                </div>
              </div>
            </a>
          </Col>
        )
      }
    </>
  );
};

LanternCardLarge.propTypes = {
  lantern: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gathering: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
    gatheringUser: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
    gatheringId: PropTypes.string.isRequired,
  }).isRequired,
};

export default LanternCardLarge;
