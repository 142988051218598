import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import GatheringSmallCard from '../gathering/smallCard';
import PageHeading from '../pageHeading';

const CreateLantern = () => {
  const { t } = useTranslation();
  const [gatheringsToDo, setGatheringsToDo] = useState(null);

  useEffect(() => {
    async function getGatheringsToDo() {
      setGatheringsToDo(await GatheringUserHelper.ListGatheringsWithoutLanternFromCurrentUser());
    }
    getGatheringsToDo();
  }, []);

  return (
    <>
      {
        gatheringsToDo && (
          <>
            <PageHeading headingText={t('lantern_create')} headingURL="/dashboard" />
            <Container>
              <Row>
                <Col className="mt-5 text-center">
                  {t('lantern_create_for_gathering')}
                </Col>
              </Row>
              <Row>
                {
                  gatheringsToDo.map((gathering) => (
                    <Col xs={6} sm={4} key={gathering.id} className="px-3">
                      <GatheringSmallCard
                        key={gathering.id}
                        gatheringId={gathering.id}
                        url={`/gathering/upload/${gathering.id}`}
                      />
                    </Col>
                  ))
                }
              </Row>
            </Container>
          </>
        )
      }
    </>
  );
};

export default CreateLantern;
