import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Tabs, Tab, Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import GatheringCard from './card';
import PageHeading from '../pageHeading';

const ListGatherings = () => {
  const [gatherings, setGatherings] = useState(null);
  const [numGatherings, setNumGatherings] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    async function getGatherings() {
      setGatherings(await GatheringUserHelper.ListGatheringsForCurrentUser());
    }
    getGatherings();
  }, []);

  useEffect(() => {
    setNumGatherings(gatherings ? gatherings.length : 0);
  }, [gatherings]);

  return (
    <>
      <PageHeading headingText={t('gather')} headingURL="/" button={t('create')} />
      <Button href="/gathering/create" className="primary create-lantern-button d-none d-sm-flex">
        <span className="pr-2">
          <FontAwesomeIcon icon={faPlus} />
        </span>
        {t('gathering_create')}
      </Button>
      <Tabs className="gathering-list-tabs px-2 pb-2" defaultActiveKey="viewAll">
        <Tab eventKey="viewAll" title={`${t('all')}  (${numGatherings})`} />
      </Tabs>
      <Container fluid className="mt-5 gathering-list">
        <Row>
          {
            gatherings && (
              gatherings.map((gathering) => (
                <>
                  <Col key={`${gathering.id}-spacer`} sm={1} />
                  <Col key={gathering.id} xs={12} sm={4}>
                    <GatheringCard key={gathering.id} gathering={gathering} />
                  </Col>
                  <Col key={`${gathering.id}-spacer`} sm={1} />
                </>
              ))
            )
          }
        </Row>
      </Container>
    </>
  );
};

export default ListGatherings;
