import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Alert,
  Spinner,
  InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from '../libs/contextLib';
import AuthHelper from '../helpers/auth.helper';

const SignInForm = () => {
  const { t } = useTranslation();
  const { userHasAuthenticated } = useAppContext();
  const location = useLocation();
  const history = useHistory();
  const [generalError, setGeneralError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = Yup.object({
    phone_number: Yup.string().phone('US', false, t('phone_invalid')).required(t('required')),
    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, t('password_invalid')).required(t('required')),
  });

  const onSubmit = async (values) => {
    try {
      setGeneralError(null);
      setIsSubmitting(true);
      // eslint-disable-next-line camelcase
      const { phone_number, password } = values;
      // eslint-disable-next-line camelcase
      const username = `+1${phone_number}`;
      const user = await AuthHelper.signIn(username, password);

      if (user) {
        userHasAuthenticated(true);

        // Redirect user to where they tried to login or to /dashboard
        if (location && location.state) {
          const { from } = location.state;
          history.replace(from);
        } else {
          history.push('/dashboard');
        }
      } else {
        setGeneralError(t('login_credentials_invalid'));
      }
    } catch (e) {
      console.log('onSubmit catch: ', e);
      setGeneralError(e.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          phone_number: '',
          password: '',
        }}
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} data-testid="signin">
            {
              generalError && (
                <Alert variant="danger">
                  {generalError}
                </Alert>
              )
            }
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMobileAlt} color="white" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="phone_number"
                  placeholder={t('phone_number')}
                  aria-describedby="inputGroupPrepend"
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.phone_number}
                  data-testid="phone_number"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone_number}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faLock} color="white" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder={t('password')}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={errors.password}
                  data-testid="password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center">
              <Button variant="primary" className="rounded-pill" type="submit" disabled={isSubmitting}>
                {
                  isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null
                }
                {t('sign_in')}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignInForm;
