import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from 'react-bootstrap';
import GatheringHelper from '../../helpers/gathering.helper';
import GatheringUsers from './users';

const GatheringCard = ({ gathering }) => {
  const [gatheringData, setGatheringData] = useState(null);

  useEffect(() => {
    async function getGatheringData() {
      setGatheringData(await GatheringHelper.GetById({
        gatheringId: gathering.id,
      }));
    }
    getGatheringData();
  }, [gathering]);

  return (
    <a href={`/gathering/view/${gathering.id}`}>
      <Card className="mt-3 mb-3 gathering-card drop-shadow">
        <Card.Body>
          {
            gatheringData && (
              <div className="gathering-card-question">
                {gatheringData.question.question}
              </div>
            )
          }
          <hr />
          <GatheringUsers gathering={gathering} />
        </Card.Body>
      </Card>
    </a>
  );
};

GatheringCard.propTypes = {
  gathering: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default GatheringCard;
