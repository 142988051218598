/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import './App.css';
import './mobile.css';
import {
  Container, Alert, Row, Col, Card,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import TimeAgo from 'javascript-time-ago';
// eslint-disable-next-line import/no-extraneous-dependencies
import en from 'javascript-time-ago/locale/en';
// eslint-disable-next-line import/no-extraneous-dependencies
import ru from 'javascript-time-ago/locale/ru';
import { AppContext } from './libs/contextLib';
import AuthHelper from './helpers/auth.helper';
import AnalyticsHelper from './helpers/analytics.helper';
import NavigationHome from './components/navigation.home';
import NavigationDesktop from './components/navigation.desktop';
import NavigationMobile from './components/navigation.mobile';
import Home from './components/home';
import SignInForm from './auth/signInForm';
import SignUp from './components/signUp';
import Dashboard from './components/user/dashboard';
import ListGatherings from './components/gathering/list';
import CreateGathering from './components/gathering/create';
import ViewGathering from './components/gathering/view';
import ListLanterns from './components/lantern/list';
import GatheringUpload from './components/gathering/upload';
import ViewLantern from './components/lantern/view';
import ViewProfile from './components/user/view';
import CreateLantern from './components/lantern/create';
import Footer from './components/footer';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [hasSignedOut, userHasSignedOut] = useState(false);

  // Send analytics data when a page is loaded (route is changed)
  const location = useLocation();
  useEffect(() => {
    AnalyticsHelper.PageLoad();
  }, [location.pathname]);

  useEffect(() => {
    async function hasValidSession() {
      if (await AuthHelper.hasValidSession()) {
        userHasAuthenticated(true);
        setIsAuthenticating(false);
      } else {
        setIsAuthenticating(false);
      }
    }
    hasValidSession();
  }, []);

  useEffect(() => {
    if (hasSignedOut) {
      userHasAuthenticated(false);
    }
  }, [hasSignedOut]);

  return (
    !isAuthenticating
    && (
    <>
      <Container className="pl-0 pr-0" fluid>
        {
            hasSignedOut
              ? <SignedOut />
              : null
          }
        <Routes
          isAuthenticated={isAuthenticated}
          userHasAuthenticated={userHasAuthenticated}
          userHasSignedOut={userHasSignedOut}
        />
      </Container>
      <Footer />
    </>
    )
  );
}

export function Routes({ isAuthenticated, userHasAuthenticated, userHasSignedOut }) {
  const { t } = useTranslation();

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <Switch>
        <Route path="/signup">
          {
            isAuthenticated
              ? <Redirect to="/dashboard" />
              : (
                <>
                  <NavigationHome />
                  <SignUp />
                </>
              )
          }
        </Route>
        <Route path="/signin">
          {
            isAuthenticated
              ? <Redirect to="/dashboard" />
              : (
                <>
                  <NavigationHome />
                  <Container className="mt-5">
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Card body>
                          <h3>{t('sign_in')}</h3>
                          <SignInForm userHasAuthenticated={userHasAuthenticated} />
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </>
              )
          }
        </Route>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/dashboard">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/gathering/list">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <ListGatherings />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/gathering/create">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <CreateGathering />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/gathering/view/:gatheringId">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <ViewGathering />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/lantern/list">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <ListLanterns />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/gathering/upload/:gatheringId">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <GatheringUpload />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/lantern/view/:lanternId">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <ViewLantern />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/profile">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <ViewProfile userHasSignedOut={userHasSignedOut} />
        </PrivateRoute>
        <PrivateRoute isAuthenticated={isAuthenticated} path="/lantern/create">
          <NavigationDesktop
            isAuthenticated={isAuthenticated}
          />
          <NavigationMobile />
          <CreateLantern />
        </PrivateRoute>
        <Route path="/">
          {
            isAuthenticated
              ? <Redirect to="/dashboard" />
              : (
                <>
                  <Home isAuthenticated={isAuthenticated} userHasSignedOut={userHasSignedOut} />
                </>
              )
          }
        </Route>
      </Switch>
    </AppContext.Provider>
  );
}

Routes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  userHasAuthenticated: PropTypes.func.isRequired,
  userHasSignedOut: PropTypes.func.isRequired,
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute(props) {
  const { isAuthenticated, children, location } = props;

  if (isAuthenticated) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Route {...props}>
        {children}
      </Route>
    );
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape(),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

PrivateRoute.defaultProps = {
  location: {},
};

function SignedOut() {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      {
      show
        ? (
          <Alert variant="success" onClose={() => setShow(false)} dismissible>
            {t('sign_out_success')}
          </Alert>
        )
        : null
    }
    </>
  );
}

export default App;
