import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import ProfileImage from '../user/profileImage';

const GatheringUsers = ({ gathering }) => {
  const [gatheringUserData, setGatheringUserData] = useState(null);

  useEffect(() => {
    async function getGatheringUsers() {
      const data = await GatheringUserHelper.ListGatheringUsersForGathering({
        gatheringId: gathering.id,
      });
      setGatheringUserData(data);
    }
    getGatheringUsers();
  }, [gathering.id]);

  return (
    <Container className="py-2">
      <Row className="text-center justify-content-center">
        {
          gatheringUserData && (
            gatheringUserData.map((gatheringUser) => (
              <Col key={gatheringUser.id} xs={2} className="participant px-0 mx-1 mb-2">
                <ProfileImage gatheringUser={gatheringUser} />
              </Col>
            ))
          )
        }
      </Row>
    </Container>
  );
};

GatheringUsers.propTypes = {
  gathering: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default GatheringUsers;
