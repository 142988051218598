import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import SignUpForm from '../auth/signUpForm';
import ConfirmSignUpForm from '../auth/confirmSignUpForm';

const SignUp = () => {
  const [formState, updateFormState] = useState({ formType: 'signUp', username: '', password: '' });

  const { formType } = formState;
  return (
    <Container className="mt-5">
      <Row>
        <Col className="d-flex justify-content-center">
          <Card body>
            {
            formType === 'signUp' && (
              <SignUpForm formState={formState} updateFormState={updateFormState} />
            )
          }
            {
            formType === 'confirmSignUp' && (
              <ConfirmSignUpForm formState={formState} />
            )
          }
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
