import { API, graphqlOperation } from 'aws-amplify';
import { settingByName } from '../graphql/queries';

async function GetByName({ name }) {
  try {
    const responseData = await API.graphql(graphqlOperation(
      settingByName,
      { name },
    ));
    return responseData.data.settingByName.items[0];
  } catch (e) {
    console.log('GetByName: ', e);
  }

  return null;
}

const SettingHelper = {
  GetByName,
};

export default SettingHelper;
