import { API, graphqlOperation } from 'aws-amplify';
import { createGathering } from '../graphql/mutations';
import { listGatherings, gatheringByUser, getGathering } from '../graphql/queries';
import UserHelper from './user.helper';
// eslint-disable-next-line import/no-cycle
import GatheringUserHelper from './gatheringUser.helper';
import AuthHelper from './auth.helper';
import DataHelper from './data.helper';

async function Create({ user, questionId }) {
  try {
    const gathering = {
      userId: user.id,
      questionId,
    };
    const result = await API.graphql(graphqlOperation(createGathering, { input: gathering }));
    return result.data.createGathering;
  } catch (e) {
    console.log('createGathering: ', e);
  }

  return null;
}

async function List() {
  try {
    const responseData = await API.graphql(graphqlOperation(listGatherings));
    return DataHelper.SortData({
      data: responseData.data.listGatherings.items,
      orderBy: 'updatedAt',
      direction: 'desc',
    });
  } catch (e) {
    console.log('getGatherings: ', e);
  }

  return null;
}

async function ListGatheringsOrganizedByCurrentUser() {
  try {
    const user = await UserHelper.Current();
    if (user) {
      const responseData = await API.graphql(graphqlOperation(
        gatheringByUser,
        { userId: user.id },
      ));
      return DataHelper.SortData({
        data: responseData.data.gatheringByUser.items,
        orderBy: 'updatedAt',
        direction: 'desc',
      });
    }
  } catch (e) {
    console.log('ListGatheringsOrganizedByCurrentUser: ', e);
  }

  return null;
}

async function GetById({ gatheringId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(getGathering, { id: gatheringId }));
    return responseData.data.getGathering;
  } catch (e) {
    console.log('GetById: ', e);
  }
}

async function SendInvites({ gatheringId }) {
  try {
    const gathering = await GetById({
      gatheringId,
    });

    const gatheringOrganizer = await AuthHelper.getUser({
      username: gathering.user.cognitoId,
    });

    const gatheringOrganizerData = {
      given_name: AuthHelper.getUserAttribute(gatheringOrganizer, 'given_name'),
      family_name: AuthHelper.getUserAttribute(gatheringOrganizer, 'family_name'),
    };

    const gatheringUsers = await GatheringUserHelper.ListGatheringUsersForGathering({
      gatheringId,
    });

    const formattedGatheringUsers = [];
    await Promise.all(gatheringUsers.map(async (gatheringUser) => {
      if (gatheringUser.user) {
        if (gatheringUser.user.cognitoId === gathering.user.cognitoId) {
          return;
        }

        const user = await AuthHelper.getUser({
          username: gatheringUser.user.cognitoId,
        });

        formattedGatheringUsers.push({
          phone_number: AuthHelper.getUserAttribute(user, 'phone_number'),
        });
      } else if (gatheringUser.invitePhoneNumber) {
        formattedGatheringUsers.push({
          phone_number: gatheringUser.invitePhoneNumber,
        });
      }
    }));

    await API.post(
      'lanternapi',
      `/gathering/${gatheringId}/sendinvites`,
      {
        body: {
          organizer: {
            given_name: gatheringOrganizerData.given_name,
            family_name: gatheringOrganizerData.family_name,
          },
          gatheringUsers: formattedGatheringUsers,
        },
        headers: {},
      },
    );
  } catch (e) {
    console.log('SendInvites: ', e);
  }
}

function IsOrganizer({ gathering, userId }) {
  return gathering.userId === userId;
}

const GatheringHelper = {
  Create,
  List,
  ListGatheringsOrganizedByCurrentUser,
  GetById,
  SendInvites,
  IsOrganizer,
};

export default GatheringHelper;
