import { API, graphqlOperation } from 'aws-amplify';
import { createLantern, createLanternProxy, updateLantern } from '../graphql/mutations';
import {
  getLantern, lanternByGatheringId, proxyByLanternId,
} from '../graphql/queries';
// eslint-disable-next-line import/no-cycle
import GatheringHelper from './gathering.helper';
// eslint-disable-next-line import/no-cycle
import GatheringUserHelper from './gatheringUser.helper';
import DataHelper from './data.helper';

async function Create({ gatheringUserId, gatheringId, pathToLantern }) {
  try {
    const lantern = {
      gatheringUserId,
      gatheringId,
      pathToLantern,
    };
    const result = await API.graphql(graphqlOperation(createLantern, { input: lantern }));
    return result.data.createLantern;
  } catch (e) {
    console.log('createLantern: ', e);
  }

  return null;
}

async function List({ gatheringId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(lanternByGatheringId, { gatheringId }));
    return DataHelper.SortData({
      data: responseData.data.lanternByGatheringId.items,
      orderBy: 'updatedAt',
      direction: 'desc',
    });
  } catch (e) {
    console.log('listLanterns: ', e);
  }

  return null;
}

async function GetById({ lanternId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(getLantern, { id: lanternId }));
    return responseData.data.getLantern;
  } catch (e) {
    console.log('GetById: ', e);
  }
}

async function UpdatePath({ lanternId, pathToLantern }) {
  try {
    const responseData = await API.graphql(graphqlOperation(
      updateLantern,
      {
        input: {
          id: lanternId,
          pathToLantern,
        },
      },
    ));
    return responseData.data.updateLantern;
  } catch (e) {
    console.log('updateLantern: ', e);
  }

  return null;
}

async function CreateProxy({ lanternId, byGatheringUserId, forGatheringUserId }) {
  try {
    const proxy = {
      lanternId,
      byGatheringUserId,
      forGatheringUserId,
    };

    const responseData = await API.graphql(graphqlOperation(createLanternProxy, { input: proxy }));
    return responseData.data.createLanternProxy;
  } catch (e) {
    console.log('createProxy: ', e);
  }

  return null;
}

async function GetProxyByLanternId({ lanternId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(proxyByLanternId, { lanternId }));
    return responseData.data.proxyByLanternId.items[0];
  } catch (e) {
    console.log('lanternByLanternId: ', e);
  }

  return null;
}

function CanModifyLantern({ lantern, currentUser }) {
  try {
    return GatheringHelper.IsOrganizer({
      gathering: lantern.gathering,
      userId: currentUser.id,
    }) || lantern.gatheringUser.userId === currentUser.id;
  } catch (e) {
    console.log('CanModifyLantern: ', e);
  }

  return false;
}

async function ListLanternsAvailableToCurrentUser() {
  try {
    const gatherings = await GatheringUserHelper.ListGatheringsForCurrentUser();

    if (gatherings) {
      const lanterns = await Promise.all(
        gatherings.map(async (gathering) => List({ gatheringId: gathering.id })),
      );
      return DataHelper.SortData({
        data: lanterns.reduce((a, b) => a.concat(b)),
        orderBy: 'updatedAt',
        direction: 'desc',
      });
    }
  } catch (e) {
    console.log('ListLanternsAvailableToCurrentUser: ', e);
  }

  return null;
}

const LanternHelper = {
  Create,
  List,
  GetById,
  UpdatePath,
  CreateProxy,
  GetProxyByLanternId,
  CanModifyLantern,
  ListLanternsAvailableToCurrentUser,
};

export default LanternHelper;
