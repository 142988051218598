import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanternTranscriptionHelper from '../../../helpers/lanternTranscription.helper';
import LanternHelper from '../../../helpers/lantern.helper';
import UserHelper from '../../../helpers/user.helper';
import EditTranscription from './edit';
import ReTranscribe from '../retranscribe';
import ReTranslateTranscription from './retranslate';

const LanternTranscription = ({ lantern }) => {
  const [transcriptionData, setTranscriptionData] = useState([{ id: '', transcript: '', language: '' }]);
  const [primaryTranscriptionData, setPrimaryTranscriptionData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function getTranscription() {
      const data = await LanternTranscriptionHelper.GetTranscriptionForLantern({
        lanternId: lantern.id,
      });
      setTranscriptionData(data);

      if (data) {
        data.forEach((transcriptData) => {
          // Assume Russian is the primary for re-translation
          if (transcriptData.language === 'ru-RU') {
            setPrimaryTranscriptionData(transcriptData);
          }
        });
      }
    }
    if (!isEditing) {
      getTranscription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    async function getCurrentUser() {
      setCurrentUser(await UserHelper.Current());
    }
    getCurrentUser();
  }, []);

  return (
    <>
      <Tabs className="lantern-transcript-locales-tabs px-4 py-2">
        {
        currentUser && transcriptionData && transcriptionData.map((data) => (
          <Tab key={data.id} eventKey={data.language} title={t(data.language)} className="px-4 py-3">
            {
              !isEditing && (
                <>
                  <div>
                    {data.transcript}
                  </div>
                  {
                    LanternHelper.CanModifyLantern({
                      lantern,
                      currentUser,
                    }) && (
                      <>
                        <div className="my-3 text-center">
                          <Button variant="secondary" className="lantern-wide-button lantern-command-button" onClick={() => setIsEditing(true)}>
                            {t('edit')}
                          </Button>
                        </div>
                        {
                          primaryTranscriptionData && data.id !== primaryTranscriptionData.id && (
                            <div className="my-3 text-center">
                              <ReTranslateTranscription
                                lantern={lantern}
                                fromTranscriptionData={primaryTranscriptionData}
                                toTranscriptionData={data}
                                language={data.language}
                              />
                            </div>
                          )
                        }
                        <div className="my-3 text-center">
                          <ReTranscribe lantern={lantern} />
                        </div>
                      </>
                    )
                  }
                </>
              )
            }
            {
              isEditing && (
                <EditTranscription
                  transcriptionData={data}
                  setIsEditing={setIsEditing}
                />
              )
            }
          </Tab>
        ))
      }
      </Tabs>
    </>
  );
};

LanternTranscription.propTypes = {
  lantern: PropTypes.shape({
    id: PropTypes.string.isRequired,
    gathering: PropTypes.shape().isRequired,
    gatheringUser: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LanternTranscription;
