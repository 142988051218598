import { API, graphqlOperation } from 'aws-amplify';
import { createUser } from '../graphql/mutations';
import { getUser, userByCognitoId } from '../graphql/queries';
import AuthHelper from './auth.helper';

async function Create(cognitoId, identityId) {
  try {
    const user = {
      cognitoId,
      identityId,
    };
    return await API.graphql(graphqlOperation(createUser, {
      input: user,
    }));
  } catch (e) {
    console.log('createUser: ', e);
  }

  return null;
}

async function GetById({ id }) {
  try {
    const responseData = await API.graphql(graphqlOperation(getUser, { id }));
    return responseData.data.getUser;
  } catch (e) {
    console.log('getUser: ', e);
  }

  return null;
}

async function GetByCognitoId({ cognitoId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(userByCognitoId, {
      cognitoId,
      limit: 1,
    }));
    return responseData.data.userByCognitoId.items[0];
  } catch (e) {
    console.log('getUser: ', e);
  }

  return null;
}

async function Current() {
  try {
    const user = await AuthHelper.currentUserInfo();
    if (user) {
      return await GetByCognitoId({
        cognitoId: user.username,
      });
    }
  } catch (e) {
    console.log('Current: ', e);
  }
}

const UserHelper = {
  Create,
  GetById,
  GetByCognitoId,
  Current,
};

export default UserHelper;
