import { API, graphqlOperation } from 'aws-amplify';
import NodeWebvtt from 'node-webvtt';
import UserHelper from './user.helper';
import { transcriptionByLanternId } from '../graphql/queries';
import { updateLanternTranscription, deleteLanternTranscription } from '../graphql/mutations';

async function GetTranscriptionForLantern({ lanternId }) {
  try {
    const responseData = await API.graphql(graphqlOperation(
      transcriptionByLanternId,
      { lanternId },
    ));

    return responseData.data.transcriptionByLanternId.items.map((data) => {
      const newData = { ...data };
      newData.url = `https://amplify-lanternapp-useruploads-dev153056-dev.s3.amazonaws.com/webvtt/${data.lantern.id}_${data.language}.vtt`;
      return newData;
    });
  } catch (e) {
    console.log('GetTranscriptionForLantern: ', e);
  }

  return null;
}

function ConvertWebVTTToText({ webvtt }) {
  const parsed = NodeWebvtt.parse(webvtt);

  let text = '';
  parsed.cues.forEach((cue) => {
    text += cue.text;
  });

  return text;
}

async function UpdateLanternTranscription({ id, webvtt }) {
  try {
    const transcript = ConvertWebVTTToText({
      webvtt,
    });

    const responseData = await API.graphql(graphqlOperation(
      updateLanternTranscription,
      { input: { id, webvtt, transcript } },
    ));

    return responseData.data.updateLanternTranscription;
  } catch (e) {
    console.log('UpdateLanternTranscription ', e);
  }

  return null;
}

async function DeleteTranscription({ id }) {
  try {
    const responseData = await API.graphql(graphqlOperation(
      deleteLanternTranscription,
      { input: { id } },
    ));

    return responseData.data.deleteLanternTranscription;
  } catch (e) {
    console.log('DeleteTranscription: ', e);
  }

  return null;
}

async function ReTranscribeLantern({ lantern }) {
  try {
    const { identityId } = await UserHelper.GetById({
      id: lantern.gathering.userId,
    });

    const responseData = await API.post(
      'retranscribelantern',
      `/lantern/${lantern.id}/retranscribe`,
      {
        body: {
          identityId,
        },
        headers: {},
      },
    );

    return responseData;
  } catch (e) {
    console.log('ReTranscribeLantern ', e);
  }

  return null;
}

async function ReTranslateTranscription({
  lantern, fromTranscriptionId, toTranscriptionId, language,
}) {
  try {
    const responseData = await API.post(
      'retranslatelantern',
      `/lantern/${lantern.id}/retranslate`,
      {
        body: {
          fromTranscriptionId,
          toTranscriptionId,
          language,
        },
        headers: {},
      },
    );

    return responseData;
  } catch (e) {
    console.log('ReTranslate: ', e);
  }

  return null;
}

const LanternTranscriptionHelper = {
  GetTranscriptionForLantern,
  UpdateLanternTranscription,
  DeleteTranscription,
  ReTranscribeLantern,
  ReTranslateTranscription,
};

export default LanternTranscriptionHelper;
