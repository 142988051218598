import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanternHelper from '../../helpers/lantern.helper';
import LanternCardLarge from './cardLarge';
import { ReactComponent as ChevronIcon } from '../../images/icons/arrow.svg';

const ExploreDashboard = () => {
  const [lanterns, setLanterns] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    async function getLanterns() {
      setLanterns(await LanternHelper.ListLanternsAvailableToCurrentUser());
    }
    getLanterns();
  }, []);

  return (
    <Container>
      <Row>
        <Col className="px-0">
          <div>
            <a className="header-link" href="/lantern/list">
              <Container>
                <Row>
                  <Col xs={10} className="px-4">
                    {t('explore')}
                  </Col>
                  <Col xs={2} className="px-4 text-right">
                    <ChevronIcon />
                  </Col>
                </Row>
              </Container>
            </a>
          </div>
        </Col>
      </Row>
      {
        lanterns && (
          lanterns.map((lantern) => (
            <Row className="mt-2">
              <LanternCardLarge key={lantern.id} lantern={lantern} />
            </Row>
          ))
        )
      }
    </Container>
  );
};

export default ExploreDashboard;
