/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGathering = /* GraphQL */ `
  mutation CreateGathering(
    $input: CreateGatheringInput!
    $condition: ModelGatheringConditionInput
  ) {
    createGathering(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      questionId
      question {
        id
        question
        gatherings {
          nextToken
        }
        createdAt
        updatedAt
      }
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGathering = /* GraphQL */ `
  mutation UpdateGathering(
    $input: UpdateGatheringInput!
    $condition: ModelGatheringConditionInput
  ) {
    updateGathering(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      questionId
      question {
        id
        question
        gatherings {
          nextToken
        }
        createdAt
        updatedAt
      }
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGathering = /* GraphQL */ `
  mutation DeleteGathering(
    $input: DeleteGatheringInput!
    $condition: ModelGatheringConditionInput
  ) {
    deleteGathering(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      questionId
      question {
        id
        question
        gatherings {
          nextToken
        }
        createdAt
        updatedAt
      }
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      gatherings {
        items {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        nextToken
      }
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const createGatheringUser = /* GraphQL */ `
  mutation CreateGatheringUser(
    $input: CreateGatheringUserInput!
    $condition: ModelGatheringUserConditionInput
  ) {
    createGatheringUser(input: $input, condition: $condition) {
      id
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      invitePhoneNumber
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGatheringUser = /* GraphQL */ `
  mutation UpdateGatheringUser(
    $input: UpdateGatheringUserInput!
    $condition: ModelGatheringUserConditionInput
  ) {
    updateGatheringUser(input: $input, condition: $condition) {
      id
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      invitePhoneNumber
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGatheringUser = /* GraphQL */ `
  mutation DeleteGatheringUser(
    $input: DeleteGatheringUserInput!
    $condition: ModelGatheringUserConditionInput
  ) {
    deleteGatheringUser(input: $input, condition: $condition) {
      id
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      userId
      user {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      invitePhoneNumber
      lanterns {
        items {
          id
          gatheringUserId
          gatheringId
          pathToLantern
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserRelationship = /* GraphQL */ `
  mutation CreateUserRelationship(
    $input: CreateUserRelationshipInput!
    $condition: ModelUserRelationshipConditionInput
  ) {
    createUserRelationship(input: $input, condition: $condition) {
      id
      fromUserId
      fromUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      toUserId
      toUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      relationshipType
      relationshipQualifier
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRelationship = /* GraphQL */ `
  mutation UpdateUserRelationship(
    $input: UpdateUserRelationshipInput!
    $condition: ModelUserRelationshipConditionInput
  ) {
    updateUserRelationship(input: $input, condition: $condition) {
      id
      fromUserId
      fromUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      toUserId
      toUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      relationshipType
      relationshipQualifier
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserRelationship = /* GraphQL */ `
  mutation DeleteUserRelationship(
    $input: DeleteUserRelationshipInput!
    $condition: ModelUserRelationshipConditionInput
  ) {
    deleteUserRelationship(input: $input, condition: $condition) {
      id
      fromUserId
      fromUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      toUserId
      toUser {
        id
        cognitoId
        gatherings {
          nextToken
        }
        identityId
        createdAt
        updatedAt
      }
      relationshipType
      relationshipQualifier
      createdAt
      updatedAt
    }
  }
`;
export const createLantern = /* GraphQL */ `
  mutation CreateLantern(
    $input: CreateLanternInput!
    $condition: ModelLanternConditionInput
  ) {
    createLantern(input: $input, condition: $condition) {
      id
      gatheringUserId
      gatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      pathToLantern
      createdAt
      updatedAt
    }
  }
`;
export const updateLantern = /* GraphQL */ `
  mutation UpdateLantern(
    $input: UpdateLanternInput!
    $condition: ModelLanternConditionInput
  ) {
    updateLantern(input: $input, condition: $condition) {
      id
      gatheringUserId
      gatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      pathToLantern
      createdAt
      updatedAt
    }
  }
`;
export const deleteLantern = /* GraphQL */ `
  mutation DeleteLantern(
    $input: DeleteLanternInput!
    $condition: ModelLanternConditionInput
  ) {
    deleteLantern(input: $input, condition: $condition) {
      id
      gatheringUserId
      gatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      gatheringId
      gathering {
        id
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        questionId
        question {
          id
          question
          createdAt
          updatedAt
        }
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      pathToLantern
      createdAt
      updatedAt
    }
  }
`;
export const createLanternTranscription = /* GraphQL */ `
  mutation CreateLanternTranscription(
    $input: CreateLanternTranscriptionInput!
    $condition: ModelLanternTranscriptionConditionInput
  ) {
    createLanternTranscription(input: $input, condition: $condition) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      transcript
      language
      webvtt
      createdAt
      updatedAt
    }
  }
`;
export const updateLanternTranscription = /* GraphQL */ `
  mutation UpdateLanternTranscription(
    $input: UpdateLanternTranscriptionInput!
    $condition: ModelLanternTranscriptionConditionInput
  ) {
    updateLanternTranscription(input: $input, condition: $condition) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      transcript
      language
      webvtt
      createdAt
      updatedAt
    }
  }
`;
export const deleteLanternTranscription = /* GraphQL */ `
  mutation DeleteLanternTranscription(
    $input: DeleteLanternTranscriptionInput!
    $condition: ModelLanternTranscriptionConditionInput
  ) {
    deleteLanternTranscription(input: $input, condition: $condition) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      transcript
      language
      webvtt
      createdAt
      updatedAt
    }
  }
`;
export const createLanternProxy = /* GraphQL */ `
  mutation CreateLanternProxy(
    $input: CreateLanternProxyInput!
    $condition: ModelLanternProxyConditionInput
  ) {
    createLanternProxy(input: $input, condition: $condition) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      byGatheringUserId
      byGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      forGatheringUserId
      forGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLanternProxy = /* GraphQL */ `
  mutation UpdateLanternProxy(
    $input: UpdateLanternProxyInput!
    $condition: ModelLanternProxyConditionInput
  ) {
    updateLanternProxy(input: $input, condition: $condition) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      byGatheringUserId
      byGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      forGatheringUserId
      forGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLanternProxy = /* GraphQL */ `
  mutation DeleteLanternProxy(
    $input: DeleteLanternProxyInput!
    $condition: ModelLanternProxyConditionInput
  ) {
    deleteLanternProxy(input: $input, condition: $condition) {
      id
      lanternId
      lantern {
        id
        gatheringUserId
        gatheringUser {
          id
          gatheringId
          userId
          invitePhoneNumber
          createdAt
          updatedAt
        }
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        pathToLantern
        createdAt
        updatedAt
      }
      byGatheringUserId
      byGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      forGatheringUserId
      forGatheringUser {
        id
        gatheringId
        gathering {
          id
          userId
          questionId
          createdAt
          updatedAt
        }
        userId
        user {
          id
          cognitoId
          identityId
          createdAt
          updatedAt
        }
        invitePhoneNumber
        lanterns {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
