import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Storage } from 'aws-amplify';
import { Formik } from 'formik';
import {
  Container, Row, Col, Form, Button, Alert, Spinner, InputGroup,
} from 'react-bootstrap';
import * as yup from 'yup';
import LanternHelper from '../../helpers/lantern.helper';
import GatheringHelper from '../../helpers/gathering.helper';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import PageHeading from '../pageHeading';

const GatheringUpload = () => {
  const [gathering, setGathering] = useState(null);
  const [currentGatheringUser, setCurrentGatheringUser] = useState(null);
  const [generalError, setGeneralError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gatheringUsers, setGatheringUsers] = useState(null);
  const { gatheringId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    async function getGathering() {
      setGathering(await GatheringHelper.GetById({
        gatheringId,
      }));
    }
    getGathering();
  }, [gatheringId]);

  useEffect(() => {
    async function getCurrentGatheringUser() {
      setCurrentGatheringUser(await GatheringUserHelper.GetCurrentGatheringUserForGathering({
        gatheringId,
      }));
    }
    getCurrentGatheringUser();
  }, [gatheringId]);

  useEffect(() => {
    async function getGatheringUsers() {
      try {
        setGatheringUsers(await GatheringUserHelper.GetGatheringUserDataForGathering({
          gatheringId,
        }));
      } catch (e) {
        console.log('getGatheringUsers: ', e);
      }
    }
    getGatheringUsers();
  }, [gatheringId]);

  const FILE_SIZE = 500000000;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'video/mp4',
    'video/quicktime',
  ];

  const schema = yup.object({
    file: yup
      .mixed()
      .required(t('upload_file_missing'))
      .test(
        'fileSize',
        (t('upload_file_too_large')),
        (value) => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        (t('upload_file_type_unsupported')),
        (value) => value && SUPPORTED_FORMATS.includes(value.type),
      ),
  });

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    if (values) {
      await LanternHelper.Create({
        gatheringUserId: currentGatheringUser.id,
        gatheringId: gathering.id,
        pathToLantern: values.file.name,
      })
        .then(async (createResult) => {
          if (createResult) {
            await Storage.put(createResult.id, values.file, {
              level: 'protected',
              contentType: values.file.type,
            })
              .then(async () => {
                await LanternHelper.UpdatePath({
                  lanternId: createResult.id,
                  pathToLantern: createResult.id,
                })
                  .then(async () => {
                    if (values.behalfOf) {
                      await LanternHelper.CreateProxy({
                        lanternId: createResult.id,
                        byGatheringUserId: currentGatheringUser.id,
                        forGatheringUserId: values.behalfOf,
                      })
                        .then((createLanternProxyResult) => {
                          if (createLanternProxyResult) {
                            history.push(`/lantern/view/${createResult.id}`);
                          } else {
                            setGeneralError(t('upload_failed'));
                          }
                        });
                    } else {
                      history.push(`/lantern/view/${createResult.id}`);
                    }
                  })
                  .catch(() => {
                    setGeneralError(t('upload_failed'));
                  });
              })
              .catch(() => {
                setGeneralError(t('upload_failed'));
              });
          } else {
            setGeneralError(t('upload_failed'));
          }
        })
        .catch(() => {
          setGeneralError(t('upload_failed'));
        });
    } else {
      setGeneralError(t('upload_file_missing'));
    }

    setIsSubmitting(false);
  };

  return (
    <>
      {
        gathering && (
          <>
            <PageHeading headingText={t('lantern_create')} headingURL={`/gathering/view/${gathering.id}`} />
            <Container>
              <Row className="py-5">
                <Col className="create-lantern-heading text-center">
                  <h2>{gathering.question.question}</h2>
                </Col>
              </Row>
              <Row className="py-5">
                <Col className="upload-lantern-heading text-center">
                  <h3>{t('upload_video')}</h3>
                  <UploadForm />
                </Col>
              </Row>
            </Container>
          </>
        )
      }
    </>
  );

  function UploadForm() {
    return (
      <Formik
        initialValues={{
          file: null,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {
            generalError && (
              <Alert variant="danger">
                {generalError}
              </Alert>
            )
}
            <Form.Group>
              <InputGroup className="my-3 justify-content-center">
                <Form.Control
                  id="file"
                  type="file"
                  name="file"
                  aria-describedby="inputGroupPrepend"
                  onChange={(event) => {
                    setFieldValue('file', event.currentTarget.files[0]);
                    setGeneralError(null);
                  }}
                  onBlur={handleBlur}
                  isInvalid={errors.file}
                  hidden
                />
                <Form.Label htmlFor="file" className="upload-button" />
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {
              values.file && !errors.file && (
                <>
                  <h3>{`${t('video_selected')}: ${values.file.name}`}</h3>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="behalfOf"
                      value={values.behalfOf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={errors.behalfOf}
                      data-testid="behalfOf"
                    >
                      {
                  gatheringUsers && currentGatheringUser && (
                    <GatheringUsers
                      gatheringUsers={gatheringUsers}
                      currentGatheringUser={currentGatheringUser}
                    />
                  )
                }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.behalfOf}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="d-flex justify-content-center">
                    <Button variant="primary" className="rounded-pill" type="submit" disabled={isSubmitting}>
                      {t('upload')}
                    </Button>
                  </Form.Group>
                </>
              )
}
            {
              isSubmitting && (
                <>
                  <h3>{`${t('uploading')}`}</h3>
                  <Spinner
                    as="span"
                    animation="border"
                    size="m"
                    role="status"
                    aria-hidden="true"
                  />
                </>
              )
}
          </Form>
        )}
      </Formik>
    );
  }
};

function GatheringUsers({ gatheringUsers, currentGatheringUser }) {
  const { t } = useTranslation();
  return (
    <>
      <option value="">{t('upload_onbehalf_of')}</option>
      {
        gatheringUsers.map((gatheringUser) => gatheringUser.id !== currentGatheringUser.id && (
          <GatheringUser gatheringUser={gatheringUser} />
        ))
      }
    </>
  );
}

GatheringUsers.propTypes = {
  gatheringUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentGatheringUser: PropTypes.shape(),
};

GatheringUsers.defaultProps = {
  currentGatheringUser: null,
};

function GatheringUser({ gatheringUser }) {
  const label = gatheringUser.given_name
    ? `${gatheringUser.given_name} ${gatheringUser.family_name}`
    : gatheringUser.invitePhoneNumber;

  return (
    <option key={gatheringUser.id} value={gatheringUser.id}>
      {label}
    </option>
  );
}

GatheringUser.propTypes = {
  gatheringUser: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    invitePhoneNumber: PropTypes.string,
    id: PropTypes.string.isRequired,
  }),
};

GatheringUser.defaultProps = {
  gatheringUser: PropTypes.shape({
    given_name: null,
    family_name: null,
    invitePhoneNumber: null,
  }),
};

export default GatheringUpload;
