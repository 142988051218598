import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Form,
  Alert,
  Button,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

// eslint-disable-next-line import/no-extraneous-dependencies
import NodeWebvtt from 'node-webvtt';
import Timecode from 'react-timecode';
import LanternTranscriptionHelper from '../../../helpers/lanternTranscription.helper';
import DeleteTranscription from './delete';

const EditTranscription = ({ transcriptionData, setIsEditing }) => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transcriptionDataJSON, setTranscriptionDataJSON] = useState(null);

  useEffect(() => {
    async function parseWebvtt() {
      if (!transcriptionData) {
        return;
      }

      const input = transcriptionData.webvtt;
      if (input) {
        const result = NodeWebvtt.parse(input);
        setTranscriptionDataJSON(result);
      }
    }
    parseWebvtt();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptionData]);

  const schema = Yup.object();

  const onSubmit = async (values) => {
    setIsSubmitting(true);
    setGeneralError(null);

    transcriptionDataJSON.cues = values.phrases.map((phrase) => {
      const newPhrase = { ...phrase };
      if (values.dirty && values.dirty[phrase.identifier]) {
        newPhrase.text = values.dirty[phrase.identifier];
      }
      return newPhrase;
    });

    const compiledWebvtt = NodeWebvtt.compile(transcriptionDataJSON);

    const updateResult = await LanternTranscriptionHelper.UpdateLanternTranscription({
      id: transcriptionData.id,
      webvtt: compiledWebvtt,
    });
    if (updateResult) {
      setIsSubmitting(false);
      setIsEditing(false);
    } else {
      setGeneralError(t('unknown_error'));
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {
      transcriptionDataJSON && (
        <div>
          <div className="edit-transcript-heading text-center mt-2 mb-4">
            <h2>{t('transcription_edit_instruction')}</h2>
          </div>
          <Formik
            initialValues={{
              phrases: transcriptionDataJSON.cues,
            }}
            validationSchema={schema}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                {
                  generalError && (
                    <Alert variant="danger">
                      {generalError}
                    </Alert>
                  )
                }
                {
                    transcriptionDataJSON.cues.map((phrase, idx) => (
                      <Form.Group key={phrase.identifier}>
                        <div>
                          <Timecode time={phrase.start * 1000} format="H:?mm:ss" />
                          {' '}
                          -
                          {' '}
                          <Timecode time={phrase.end * 1000} format="H:?mm:ss" />
                        </div>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          name={`dirty[${phrase.identifier}]`}
                          defaultValue={values.phrases[idx].text.replace('\n', ' ')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!(errors.dirty && errors.dirty[phrase.identifier])}
                        />
                      </Form.Group>
                    ))
                  }
                <Form.Group className="d-flex justify-content-center">
                  <Container className="mt-3">
                    <Row>
                      <Col className="text-center">
                        <Button variant="secondary" className="lantern-wide-button lantern-command-button mt-1" onClick={() => setIsEditing(false)}>
                          {t('cancel')}
                        </Button>
                      </Col>
                      <Col className="text-center">
                        <Button variant="secondary" className="lantern-wide-button lantern-command-button mt-1 " type="submit" disabled={isSubmitting}>
                          {
                            isSubmitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-2"
                              />
                            )
                          }
                          {t('save')}
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col className="mt-5 text-center">
                        <DeleteTranscription transcriptionData={transcriptionData} />
                      </Col>
                    </Row>
                  </Container>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      )
    }
    </>
  );
};

EditTranscription.propTypes = {
  transcriptionData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    webvtt: PropTypes.string.isRequired,
  }).isRequired,
  setIsEditing: PropTypes.func.isRequired,
};

export default EditTranscription;
