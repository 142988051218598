import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GatheringUserHelper from '../../helpers/gatheringUser.helper';
import { ReactComponent as PlaceholderImage } from '../../images/icons/Account.svg';

const ProfileImage = ({ gatheringUser }) => {
  const [userData, setUserData] = useState({ initials: '' });

  useEffect(() => {
    async function getUserData() {
      const data = await GatheringUserHelper.GetGatheringUserData({
        gatheringUser,
      });

      if (data) {
        setUserData({
          initials: `${data.given_name[0].toUpperCase()}${data.family_name[0].toUpperCase()}`,
        });
      }
    }
    getUserData();
  }, [gatheringUser]);

  return (
    <>
      {
        gatheringUser && (
          <div className="profile-placeholder">
            {
              userData.initials && (
                <span>{userData.initials}</span>
              )
            }
            {
              !userData.initials && (
                <PlaceholderImage />
              )
            }
          </div>
        )
      }
    </>
  );
};

ProfileImage.propTypes = {
  gatheringUser: PropTypes.shape().isRequired,
};

export default ProfileImage;
