import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  Container, Row, Col, Nav, Navbar, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NavigationDesktop = ({ isAuthenticated }) => (
  <Container fluid className="lantern-desktop-navbar mx-0 d-none d-sm-flex drop-shadow px-5">
    <Row className="w-100">
      <Col>
        <Home />
      </Col>
      <Col className="pr-0">
        <Navbar className="justify-content-end h-100 px-0">
          <Nav>
            {
              isAuthenticated
                ? (
                  <>
                    <Explore />
                    <Create />
                    <Gather />
                    <Profile />
                  </>
                )
                : (
                  <>
                    <SignIn />
                    <SignUp />
                  </>
                )
            }
          </Nav>
        </Navbar>
      </Col>
    </Row>
  </Container>
);

NavigationDesktop.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

function Home() {
  return (
    <Navbar.Brand href="/">
      <span className="logo">
        lantern
        <span className="logo-dot">.</span>
      </span>
    </Navbar.Brand>
  );
}

function Profile() {
  const { t } = useTranslation();
  return (
    <Nav.Item>
      <NavLink to="/profile">
        {t('profile')}
      </NavLink>
    </Nav.Item>
  );
}

function SignIn() {
  const { t } = useTranslation();
  return (
    <Nav.Item className="px-4">
      <NavLink to="/signin">
        {t('sign_in')}
      </NavLink>
    </Nav.Item>
  );
}

function SignUp() {
  const { t } = useTranslation();
  return (
    <Nav.Item>
      <Button href="/signup" className="rounded-pill">
        {t('sign_up')}
      </Button>
    </Nav.Item>
  );
}

function Explore() {
  const { t } = useTranslation();
  return (
    <Nav.Item>
      <NavLink to="/lantern/list">
        {t('explore')}
      </NavLink>
    </Nav.Item>
  );
}

function Create() {
  const { t } = useTranslation();
  return (
    <Nav.Item>
      <NavLink to="/lantern/create">
        {t('create')}
      </NavLink>
    </Nav.Item>
  );
}

function Gather() {
  const { t } = useTranslation();
  return (
    <Nav.Item>
      <NavLink to="/gathering/list">
        {t('gather')}
      </NavLink>
    </Nav.Item>
  );
}

export default NavigationDesktop;
