import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Hero from './hero';
import NavigationHome from './navigation.home';
import SignInForm from '../auth/signInForm';

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <Hero>
        <Container className="h-100">
          <Row>
            <Col sm={14}>
              <NavigationHome />
            </Col>
          </Row>
          <Row className="h-100 justify-content-center align-items-center">
            <Col sm={8}>
              <h1>{t('homepage_title')}</h1>
            </Col>
            <Col sm={4}>
              <div className="sign-in">
                <SignInForm />
              </div>
            </Col>
          </Row>
        </Container>
      </Hero>
    </>
  );
};

export default Home;
